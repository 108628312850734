import fetch from './dFetch';

export function getAirdropTotalTansferByAddress(address: string) {
  return fetch({
    url: '/airdrop/total',
    method: 'GET',
    params: {
      address,
    },
  });
}

export type distributeDto = {
  payer: string;
  deeperChainSign: string;
  bscSign: string;
  target: string;
};
export async function distributeAirdrop({
  payer,
  deeperChainSign,

  bscSign,
  target,
}: distributeDto) {
  return fetch({
    url: '/airdrop/distribute',
    method: 'POST',
    data: {
      payer,
      deeperChainSign,
      bscSign,
      target,
    },
  });
}

export async function getDistributionList(address: string) {
  return fetch({
    url: '/airdrop/distribute',
    method: 'GET',
    params: {
      address,
    },
  });
}
