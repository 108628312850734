const abi = require('./abi.json');
module.exports = {
  5: {
    address: '0x0255E3Bd3aA1c8c2724e8F16d7a52E533CFf7bdD',
    abi,
  },
  56: {
    address: '0x8A7B5Fe6F43DB70affC51f3881fF79354640f3E7',
    abi,
  },
};
