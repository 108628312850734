import AdminApi from 'apis/admin';
import { useRequest } from 'ahooks';
import { Table, Button, Modal, Tag } from 'antd';
import moment from 'moment';
import Search from 'components/Search/Search';
import { AirdropUser } from 'types/types';

function Airdrop() {
  const {
    run: getList,
    data: userList,
    loading: getListLoading,
  } = useRequest(AdminApi.airdrop.getUser, {
    onSuccess(data) {
      console.log(data);
    },
  });

  return (
    <div>
      <div style={{ marginBottom: 30, display: 'flex', gap: 20 }}>
        <Search onSearch={(val: string) => getList(val)} />
        {/* <Button onClick={() => {}}>Add Limit</Button> */}
      </div>
      <Table<AirdropUser>
        rowKey='_id'
        dataSource={userList}
        loading={getListLoading}
        columns={[
          {
            title: 'deeperChain',
            dataIndex: 'deeperChain',
          },
          {
            title: 'amount',
            dataIndex: 'amount',
          },
          {
            title: 'amountNum',
            dataIndex: 'amountNum',
          },
          {
            title: 'transferCount',
            dataIndex: 'transferCount',
          },
          {
            title: 'stakingInfo',
            dataIndex: 'stakingInfo',
            render(_, item) {
              return (
                <div>
                  <div>
                    {item.haveStaking && <Tag color='success'>Staking</Tag>}
                  </div>
                  <div>{JSON.stringify(item.stakingInfo)}</div>
                </div>
              );
            },
          },
          {
            title: 'createdAt',
            dataIndex: 'createdAt',
            render(time) {
              if (!time) {
                return '';
              }
              return moment(time).format();
            },
          },
          {
            title: 'action',
            render(_, record) {
              return (
                <div>
                  {/* <Button danger onClick={() => {}}>
                    Delete
                  </Button> */}
                </div>
              );
            },
          },
        ]}
      />
    </div>
  );
}

export default Airdrop;
