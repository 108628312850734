import React from 'react';

import { Table, Tag } from 'antd';

import { useRequest } from 'ahooks';

import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { getDistributionList } from 'apis/airdrop';

export default function DistributionList({ address }) {
  const { t } = useTranslation();
  const { data: list, loading: listLoading } = useRequest(getDistributionList, {
    defaultParams: [address],
    pollingInterval: 30000,
    onSuccess(res) {
      console.log(res);
    },
  });

  const columns = [
    {
      title: t('Time'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render(createdAt) {
        return moment(createdAt).format();
      },
    },
    {
      title: t('Deeper Chain Wallet'),
      dataIndex: 'deeperChain',
      key: 'deeperChain',
    },
    {
      title: t('Status'),
      dataIndex: '_id',
      key: '_id',
      render(_id, item) {
        if (item.releaseInfo) {
          return (
            <div>
              <Tag color='success'>{t('Airdropped')}</Tag>
            </div>
          );
        }
        if (item.releaseWaiting) {
          return (
            <div>
              <Tag color='warning'>{t('Waiting')}</Tag>
            </div>
          );
        }
        return (
          <div>
            <Tag color='processing'>{t('Processing')}</Tag>
          </div>
        );
      },
    },
  ];
  return (
    <div style={{ maxWidth: 1200, margin: '30px auto' }}>
      <h3>{t('Distribution List')}</h3>
      <Table rowKey='_id' dataSource={list} columns={columns} />
    </div>
  );
}
