import React, { useState } from 'react';
import AsdtApi from 'apis/adsc';
import { Table, Button, Tag, Modal } from 'antd';
import {
  CheckCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { encodeDeeperChain } from 'w3/polkadot';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import styles from './Bridge.module.scss';

export function useBridgeRecord() {
  const [deeperChain, setDeeperChain] = useState('');
  const {
    data: list = [],
    loading: listLoading,
    run: runGetRecord,
  } = useRequest(AsdtApi.adscToDprRecord, {
    manual: true,
    pollingInterval: 10000,
    onBefore(params) {
      setDeeperChain(params[0]);
    },
    onSuccess(res) {
      console.log(res);
    },
  });
  const { run: runCancel, loading: cancelLoading } = useRequest(
    AsdtApi.cancelAdscToDpr,
    {
      manual: true,
      onSuccess(res) {
        console.log(res);
        runGetRecord(deeperChain);
      },
    },
  );
  return {
    list,
    listLoading,
    cancelLoading,
    runGetRecord,
    runCancel,
  };
}

export default function BridgeRecord({ theHook }) {
  const { t } = useTranslation();
  const { list, cancelLoading, runCancel } = theHook;
  const [modal, contextHolder] = Modal.useModal();

  const columns = [
    {
      title: t('Time'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render(createdAt) {
        return moment(createdAt).format();
      },
    },
    {
      title: t('Deeper Chain'),
      dataIndex: 'deeperChain',
      key: 'deeperChain',
      className: styles['address-box'],
    },
    {
      title: t('BSC'),
      dataIndex: 'bsc',
      key: 'bsc',
      className: styles['address-box'],
    },
    {
      title: t('Quantity'),
      dataIndex: 'amount',
      key: 'amount',
      render(amount) {
        return <div>{amount} ADSC</div>;
      },
    },
    {
      title: t('Fee'),
      dataIndex: 'fee',
      key: 'fee',
      render(fee) {
        return <div>{fee} ADSC</div>;
      },
    },
    {
      title: t('Net Quantity'),
      dataIndex: 'amount',
      key: 'amount',
      render(_, item) {
        return <div>{(item.amount - item.fee) / 2} DPR</div>;
      },
    },
    {
      title: t('ADSC Hash'),
      dataIndex: 'transferredHash',
      key: 'transferredHash',
      className: styles['address-box'],
    },
    {
      title: t('DPR Hash'),
      dataIndex: 'exchangedHash',
      key: 'exchangedHash',
      className: styles['address-box'],
    },
    {
      title: t('Status'),
      dataIndex: 'status',
      key: 'status',
      render(status, item) {
        if (status == 0) {
          return (
            <div>
              <Tag icon={<SyncOutlined spin />} color='default'>
                {t('Waiting to receive ADSC')}
              </Tag>
              <Button
                size='small'
                onClick={async () => {
                  const confirmed = await modal.confirm({
                    title: t('Confirm'),
                    content: t(
                      'Are you sure you want to cancel this application?',
                    ),
                  });
                  if (confirmed) {
                    runCancel(item._id);
                  }
                }}
                danger
                loading={cancelLoading}
              >
                {t('Cancel Application')}
              </Button>
            </div>
          );
        }
        if (status == 1) {
          return (
            <div>
              <Tag icon={<CheckCircleOutlined />} color='success'>
                {t('Transferred ADSC')}
              </Tag>
            </div>
          );
        }
        if (status == 2) {
          return (
            <div>
              <Tag icon={<CheckCircleOutlined />} color='success'>
                {t('Exchanged DPR')}
              </Tag>
            </div>
          );
        }
        if (status == 3) {
          return (
            <div>
              <Tag icon={<CheckCircleOutlined />} color='default'>
                {t('Cancelled')}
              </Tag>
            </div>
          );
        }
      },
    },
  ];
  return (
    <div style={{ maxWidth: 1200, margin: '30px auto' }}>
      <Table rowKey='_id' dataSource={list} columns={columns} />
      {contextHolder}
    </div>
  );
}
