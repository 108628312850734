import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useRequest } from 'ahooks';
import Header from 'components/Header/Header';
import worldImg from 'assets/imgs/worldmap-bg.png';

import styles from './StakingCode.module.scss';
import { Select, Spin, Card, Modal, Button, Input, message } from 'antd';
import ADSCApi from 'apis/adsc';

import { useSearchParams } from 'react-router-dom';
import { bind, getCodeInfo, getDeeperChainBySn } from 'apis/stakingCode';
import { StakingCodeBindingDto } from 'types/types';

function StakingCode() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const searchCode = searchParams.get('code');
  const [code, setCode] = useState('');
  const [bindingInfo, setBindingInfo] = useState<StakingCodeBindingDto | null>(
    null,
  );
  const [sn, setSn] = useState('');

  const { run: runGetCodeInfo, loading: runGetCodeInfoLoading } = useRequest(
    getCodeInfo,
    {
      manual: true,
      onSuccess(res) {
        console.log(res);
        if (!res.binded) {
          setBindingInfo({
            binded: false,
          });
        } else {
          setBindingInfo(res);
        }
      },
    },
  );

  const { run: runBind, loading: runBindLoading } = useRequest(bind, {
    manual: true,
    onSuccess(res) {
      console.log(res);
    },
  });

  useEffect(() => {
    if (searchCode) {
      if (searchCode.length !== 36) {
        return message.error(t('Invalid staking code'));
      }
      setCode(searchCode);
    }
  }, [searchCode]);

  useEffect(() => {
    console.log(code);
    if (code && code.length === 36) {
      runGetCodeInfo(code);
    }
    setBindingInfo(null);
  }, [code]);

  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: `url(${worldImg})`,
      }}
    >
      {useMemo(() => {
        return <Header></Header>;
      }, [])}
      <div style={{ height: 20 }}></div>
      <div className={styles['search-box']}>
        <Input
          placeholder={t('Please input staking code.')}
          allowClear
          size='large'
          maxLength={36}
          onChange={(e) => {
            setCode(e.target.value);
          }}
          value={code}
        />
      </div>
      {runGetCodeInfoLoading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 100,
            background: 'rgba(255,255,255,0.5)',
          }}
        >
          <Spin spinning={true}></Spin>
        </div>
      )}
      {bindingInfo && bindingInfo.binded === false && (
        <React.Fragment>
          <div className={styles['bind-box']}>
            <Input
              placeholder={
                t('Please input device SN.') + 'Deeper-Wire-ADSC-xxxx|xxxx'
              }
              allowClear
              size='large'
              onChange={(e) => {
                setSn(e.target.value);
              }}
              value={sn}
            />
          </div>
          <div
            className={styles['bind-box']}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Button
              onClick={() => {
                runBind(code, sn);
              }}
              className={styles['bind-btn']}
              type='primary'
              size='large'
              loading={runBindLoading}
            >
              {t('Bind')}
            </Button>
          </div>
        </React.Fragment>
      )}
      {bindingInfo && bindingInfo.binded === true && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 20,
            fontSize: 14,
            justifyContent: 'center',
            alignItems: 'center',
            margin: '30px 0',
            background: 'rgba(255,255,255,0.5)',
            padding: '30px 0',
          }}
        >
          <div style={{ wordBreak: 'break-all' }}>
            <b>{bindingInfo.sn}</b>
          </div>
          <div style={{ wordBreak: 'break-all' }}>
            <b>{bindingInfo.deeperChain}</b>
          </div>
        </div>
      )}
    </div>
  );
}

export default StakingCode;
