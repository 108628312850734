import fetch from './dFetch';

export function getCodeInfo(code: string) {
  return fetch({
    url: '/stakingCode/code',
    method: 'GET',
    params: {
      code,
    },
  });
}

export function getDeeperChainBySn(sn: string) {
  return fetch({
    url: '/stakingCode/getDeeperChainBySn',
    method: 'GET',
    params: {
      sn,
    },
  });
}

export function bind(code: string, sn: string) {
  return fetch({
    url: '/stakingCode/bind',
    method: 'POST',
    data: {
      code,
      sn,
    },
  });
}
