import Web3 from 'web3';
import { AdscToken } from '../ADSC/AdscToken';
import { sendTx } from 'w3/WWeb3';
const abi = require('./abi.json');

export class AdscStakingToken {
  address: string = '';
  abi: any;
  web3: Web3;
  chainId: number;
  constructor(web3: Web3, chainId: number) {
    this.web3 = web3;
    if (chainId === 56) {
      this.address = '0x624Ab853734E095A808185041591d6E0d6142eb3';
      this.abi = abi;
      this.chainId = chainId;
    } else {
      throw new Error('InvalidChainId');
    }
  }

  async getStaking(address: string) {
    const contract = new this.web3.eth.Contract(this.abi, this.address);
    const staking = await contract.methods.getStaking(address).call();
    return staking;
  }

  async stake(address: string, amount: number) {
    const token = new AdscToken(this.web3, this.chainId);
    const allowance = await token.checkAllowance(address, this.address);
    console.log('allowance', allowance);
    const amountString = this.web3.utils.toWei(amount.toString(), 'ether');
    const contract = new this.web3.eth.Contract(this.abi, this.address);
    const tx = contract.methods.stake(address, amountString);

    return sendTx(this.web3, tx, address);
  }

  async addAndExtendStaking(address: string, amount: number) {
    const amountString = this.web3.utils.toWei(amount.toString(), 'ether');
    const contract = new this.web3.eth.Contract(this.abi, this.address);
    const tx = contract.methods.addAndExtendStaking(amountString);

    return sendTx(this.web3, tx, address);
  }
}
