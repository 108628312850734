export const en = {
  InvalidEmail: 'Invalid email',
  AlreadySent: 'A verification code has been sent, please try again later.',
  InvalidCode: 'Invalid code',
  EmailRegistered: 'The email has been registered.',
  LoginError: 'Username or password entered incorrectly.',
  InvalidBody: 'Please check the information again.',
  SNError: 'The device SN number is incorrect or already occupied.',
  BEP20Error: 'BEP20 address is incorrect or already occupied.',
  NoDeeperChain: 'Please bind the DeeperChain wallet on the device.',
  InvalidReferer: 'Invalid Referer',

  SendSuccess: 'Verification code sent successfully',
  RegisterSuccess: 'registration success',
  Welcome: 'Welcome, ',
  PasswordResetSuccess: 'Password reset successfully.',
  CreateSuccess: 'Created successfully.',
  DeleteSuccess: 'Delete successfully.',
  StakedRecently:
    'This address has been pledged within 7 days. If you are sure you want to pledge, please contact the administrator.',
  HaveRecord: 'Submitted today',
  InvalidStakingCode: 'Invalid Staking Code',
  StakingCodeBinded: 'The staking code has been bound',
  InvalidSNDeeperChain: 'The SN number is incorrect or the wallet is not bound',
  InvalidSN: 'SN is wrong or not supported',
  InvalidDprStakingAmount: 'Insufficient DPR staked',
  InvalidConvertedDprStakingAmount: 'Insufficient ADSC staked',
  InvalidDeeperChainWallet: 'You need to bind your wallet to your device first',
};

export const zh = {
  InvalidEmail: '邮箱不正确',
  AlreadySent: '验证码已发送，请稍后再试。',
  InvalidCode: '验证码错误',
  EmailRegistered: '邮箱已经被注册。',
  LoginError: '用户名或者密码输入错误。',
  InvalidBody: '请再次检查并完善信息。',
  SNError: '设备SN号不正确或已被占用。',
  BEP20Error: 'BEP20 地址不正确或已被占用。',
  NoDeeperChain: '请在设备上绑定DeeperChain钱包',
  InvalidReferer: '推荐人填写错误。',

  SendSuccess: '验证码发送成功。',
  RegisterSuccess: '注册成功!',
  Welcome: '欢迎您，',
  PasswordResetSuccess: '密码重置成功！',
  CreateSuccess: '创建成功！',
  DeleteSuccess: '删除成功!',
  StakedRecently: '该地址在7天之内质押过，如果确定要质押，请联系管理员',
  HaveRecord: '今日已提交',
  InvalidStakingCode: '质押码不正确',
  StakingCodeBinded: '质押码已经被绑定了',
  InvalidSNDeeperChain: 'SN号码不对或者没有绑定钱包',
  InvalidSN: 'SN错误或者不支持',
  InvalidDprStakingAmount: 'DPR质押数量不足',
  InvalidConvertedDprStakingAmount: 'ADSC质押数量不足',
  InvalidDeeperChainWallet: '请先在您的设备上绑定钱包。',
};
