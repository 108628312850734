import { en as ResEn } from './res';

const en = {
  Res: {
    ...ResEn,
  },
  'Deeper Network Staking': 'Deeper Network Staking',
  'register|Please input your password!':
    'Please enter a complex password (at least 8 digits, including numbers and letters)',
  Register: 'Register',
  CAMPAIGN_ID_NULL: 'nonactivated',
  CAMPAIGN_ID_0: 'Genesis Gold',
  CAMPAIGN_ID_1: 'Genesis Silver',
  CAMPAIGN_ID_2: 'Basic Mining 1.0',
  CAMPAIGN_ID_3: 'DPRB Mining',
  CAMPAIGN_ID_4: 'Basic Mining 2.0',
  CAMPAIGN_ID_5: 'Easy Mining',
  AirdropTips:
    'Transfer 500,000 ADSC to receive one round of airdrop distribution. The distributed address must be an ADSC active address, and new addresses are invalid.',
  DistributeTips:
    'The wallet address that receives the airdrop must be an ADSC active address, and other addresses are invalid.',
  tutorial: {
    Step1: 'Step1',
    Step1Content: 'Create a DeeperChain wallet in the device.',
    Step2: 'Step2',
    Step3: 'Step3',
    Step4: 'Step4',
    Step4Content: 'Open the BSC staking website and register',
    Step5: 'Step5',
    Step5Content:
      'Login to the BSC staking website, select the staking level and stake',
    Step6: 'Step6',
    Step6Content:
      'Login to the BSC staking website and refresh the page to check the staking status',
  },
};

export default en;
