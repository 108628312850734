import Web3 from 'web3';
import { sendTx } from 'w3/WWeb3';
const abi = require('./abi.json');

const maxAllowance = '1000000000000';

export class AdscToken {
  address: string = '';
  abi: any;

  web3: Web3;
  constructor(web3: Web3, chainId: number) {
    this.web3 = web3;
    if (chainId === 56) {
      this.address = '0x8A7B5Fe6F43DB70affC51f3881fF79354640f3E7';
      this.abi = abi;
    } else {
      throw new Error('InvalidChainId');
    }
  }

  async getBalance(address: string) {
    const contract = new this.web3.eth.Contract(this.abi, this.address);
    const balance = await contract.methods.balanceOf(address).call();
    return balance;
  }

  async getAllowance(owner: string, spender: string): Promise<string> {
    const contract = new this.web3.eth.Contract(this.abi, this.address);
    const allowance = await contract.methods.allowance(owner, spender).call();
    return allowance;
  }

  async checkAllowance(owner: string, spender: string): Promise<string> {
    const contract = new this.web3.eth.Contract(this.abi, this.address);
    const allowance = await contract.methods.allowance(owner, spender).call();
    if (allowance !== this.web3.utils.toWei(maxAllowance, 'ether')) {
      await this.approve(owner, spender);
      return this.checkAllowance(owner, spender);
    }
    return allowance;
  }

  async approve(owner: string, spender: string) {
    const amountString = this.web3.utils.toWei('1000000000000', 'ether');
    const contract = new this.web3.eth.Contract(this.abi, this.address);
    const tx = contract.methods.approve(spender, amountString);
    return sendTx(this.web3, tx, owner);
  }

  async transfer(from: string, to: string, amount: string) {
    const amountString = this.web3.utils.toWei(amount, 'ether');
    const contract = new this.web3.eth.Contract(this.abi, this.address);
    const tx = contract.methods.transfer(to, amountString);
    return sendTx(this.web3, tx, from);
  }
}
