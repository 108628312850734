import React, { useState, useEffect, useMemo } from 'react';
import BigNumber from 'bignumber.js';
import styles from './Bridge.module.scss';
import { Button, Input, Spin, Select, Modal } from 'antd';
import Header from 'components/Header/Header';
import { useTranslation } from 'react-i18next';
import { SwapOutlined } from '@ant-design/icons';
import BridgeRecord from './BridgeRecord';

import AdscApi from 'apis/adsc';

import BscIcon from 'assets/imgs/BNB-chain.png';
import EthIcon from 'assets/imgs/ETH.png';
import DeeperIcon from 'assets/imgs/logo-white-sm.png';
import AdscIcon from 'assets/imgs/ADSC.png';
import EthApi from 'w3/eth/index';
import {
  getPolkadotAccountList,
  checkPolkadotjs,
  signString,
  decodeDeeperChain,
} from 'w3/polkadot';
import { useRequest } from 'ahooks';
const {
  switchBSC,
  getCurrentInfo,
  checkMetamask,
  getAdscBalance: ETH_getAdscBalance,
  ethToDeeperChain,
  deeperChainToEth,
} = EthApi;

const Limit = {
  max: 200000000,
  min: 1000,
  maxTotal: 200000000,
};

function Bridge() {
  const [direction, setDirection] = useState(() => {
    let storeDirection = window.localStorage.getItem('direction')
      ? window.localStorage.getItem('direction') / 1
      : 1;
    return storeDirection;
  });
  const [amount, setAmount] = useState(10000);
  const [network, setNetwork] = useState('BSC');
  const [chainId, setChainId] = useState(56);
  const [ethAccount, setEthAccount] = useState('');
  const [deeperAccount, setDeeperAccount] = useState('');
  const { t } = useTranslation();
  const [modal, contextHolder] = Modal.useModal();
  const [initStatus, setInitStatus] = useState(() => {
    const metamask = checkMetamask();

    if (!metamask) {
      return 1;
    }
    return 0;
  });
  useEffect(() => {
    if (initStatus == 1) {
      modal.confirm({
        title: 'Install MetaMask',
        content: 'Please install MetaMask',
        onOk: () => {
          window.open(
            'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn',
          );
        },
      });
    }
    if (initStatus == 2) {
      modal.confirm({
        title: 'Install polkadot{.js} extension',
        content: 'Please install polkadot{.js} extension',
        onOk: () => {
          window.open(
            'https://chrome.google.com/webstore/detail/polkadot%7Bjs%7D-extension/mopnmbcafieddcagagdcbnhejhlodfdd',
          );
        },
      });
    }
  }, [initStatus]);

  const {
    data: currentInfo,
    loading: getCurrentInfoLoading,
    run: runGetCurrentInfo,
  } = useRequest(getCurrentInfo, {
    onSuccess(res) {
      console.log('currentInfo');
      setEthAccount(res.account);
      setChainId(res.networkId);
      if (res.networkId === 56) {
        setNetwork('BSC');
      } else {
        switchNetwork();
      }
    },
    onError(e) {
      console.log(e);
      modal.error({
        title: 'Error',
        content: e.message,
      });
    },
  });
  const {
    data: deeperChainAccountList = [],
    loading: getDeeperChainAccountLoading,
  } = useRequest(getPolkadotAccountList, {
    onSuccess(res) {
      console.log(res);
      if (res[0]) {
        setDeeperAccount(res[0].address);
      } else {
        if (checkPolkadotjs()) {
          setInitStatus(2);
        }
      }
    },
    onError(e) {
      console.log(e);
      modal.error({
        title: 'Error',
        content: e.message,
      });
    },
  });

  const { run: switchNetwork, loading: switchLoading } = useRequest(
    (network) => {
      return switchBSC();
    },
    {
      manual: true,
      onError(e) {
        console.log(e);
        modal.error({
          title: 'Error',
          content: e.message,
        });
      },
    },
  );

  const { data: remainingAmount = 0, run: runGetBridgeRemainingAmount } =
    useRequest(AdscApi.getBridgeRemainingAmount, {
      manual: true,
      onSuccess(res) {
        console.log('remainingAmount');
        console.log(res);
      },
    });

  useEffect(() => {
    if (ethAccount) {
      runGetBridgeRemainingAmount(ethAccount);
    }
  }, [ethAccount, runGetBridgeRemainingAmount]);

  const {
    data: deeperAsdtBalance = { balance: 0 },
    run: runGetDeeperAdscBalance,
    loading: runGetDeeperAdscBalanceLoading,
  } = useRequest(AdscApi.getBalance, {
    manual: true,
    onSuccess(res) {
      console.log(res);
    },
    onError(e) {
      console.log(e);
      modal.error({
        title: 'Error',
        content: e.message,
      });
    },
  });

  const {
    data: ethAsdcBalance = 0,
    run: runGetEthAdscBalance,
    loading: runGetEthAdscBalanceLoading,
  } = useRequest(ETH_getAdscBalance, {
    manual: true,
    onSuccess(res) {
      console.log(res);
    },
    onError(e) {
      console.log(e);
      modal.error({
        title: 'Error',
        content: e.message,
      });
    },
  });

  useEffect(() => {
    if (
      initStatus == 0 &&
      deeperChainAccountList.length == 0 &&
      !getDeeperChainAccountLoading
    ) {
      modal.warning({
        title: 'Import Deeper Chain Wallet',
        content: 'Please import Deeper Chain wallet in polkadot{.js} extension',
      });
    }
  }, [initStatus, deeperChainAccountList, getDeeperChainAccountLoading]);

  useEffect(() => {
    if (deeperAccount) {
      runGetDeeperAdscBalance(deeperAccount);
    }
  }, [deeperAccount]);
  useEffect(() => {
    if (ethAccount && Number(chainId) === 56) {
      runGetEthAdscBalance(ethAccount);
    }
  }, [ethAccount, chainId]);
  const ETHComp = () => (
    <div>
      {network === 'BSC' && (
        <div className={styles['network-info']}>
          <div className={styles['logo-box']}>
            <img src={BscIcon} alt='' />
          </div>

          <div className={styles['network-label']}>Smart Chain</div>
        </div>
      )}
      {network === 'ETH' && (
        <div className={styles['network-info']}>
          <div className={styles['logo-box']}>
            <img src={EthIcon} alt='' />
          </div>
          <div className={styles['network-label']}>Ethereum</div>
        </div>
      )}
    </div>
  );
  const DeeperComp = () => (
    <div className={styles['network-info']}>
      <div className={styles['logo-box']}>
        <img src={DeeperIcon} alt='' />
      </div>
      <div className={styles['network-label']}>Deeper Chain</div>
    </div>
  );
  const SwitchBtn = () => (
    <div>
      {network === 'BSC' && (
        <Button
          loading={switchLoading}
          onClick={() => switchNetwork(network)}
          ghost
        >
          {t('Switch to')} ETH
        </Button>
      )}
      {network === 'ETH' && (
        <Button
          loading={switchLoading}
          onClick={() => switchNetwork(network)}
          ghost
        >
          {t('Switch to')} BSC
        </Button>
      )}
    </div>
  );

  const DeeperChainAccountListComp = () => (
    <Select
      style={{ width: '100%' }}
      showSearch
      value={deeperAccount}
      placeholder={t('Select a Deeper Chain account')}
      optionFilterProp='children'
      onChange={(val) => {
        setDeeperAccount(val);
      }}
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      options={deeperChainAccountList.map((it) => {
        return {
          label: `${it.meta.name} - ${it.address}`,
          value: it.address,
        };
      })}
    />
  );

  useEffect(() => {
    if (window.ethereum) {
      // 监听钱包切换事件
      window.ethereum.on('accountsChanged', function (accounts) {
        window.location.reload();
      });
      window.ethereum.on('chainChanged', function (chainId) {
        window.location.reload();
      });
    }
  }, [setNetwork, setEthAccount]);
  const onChangeAmount = (e) => {
    if (e.target.value === '') {
      setAmount('');
      return;
    }
    let value = e.target.value / 1;
    if (!value) {
      return;
    }
    setAmount(value);
  };
  const transfer = useMemo(
    () => async () => {
      let tAmount = BigNumber(amount * 1e18).toFixed();
      console.log(direction);
      if (direction == 2) {
        await ethToDeeperChain(
          decodeDeeperChain(deeperAccount),
          ethAccount,
          tAmount,
        );
        return;
      }
      if (direction == 1) {
        if (remainingAmount - amount < 0) {
          const confirmRes = await new Promise((resolve) => {
            modal.confirm({
              title: t('Insufficient quota'),
              content: t(
                'Today’s quota is no longer able to withdraw this amount of coins. Are you sure you want to continue to apply?',
              ),
              onOk() {
                resolve(true);
              },
              onCancel() {
                resolve(false);
              },
            });
          });
          if (!confirmRes) {
            return;
          }
        }
        const account = deeperChainAccountList.filter(
          (it) => it.address == deeperAccount,
        )[0];
        const sig = await signString(ethAccount, account);
        await deeperChainToEth(
          decodeDeeperChain(deeperAccount),
          ethAccount,
          tAmount,
          sig,
        );
        return;
      }
    },
    [amount, deeperAccount, remainingAmount, ethAccount, direction],
  );
  const { run: runTransfer, loading: runTransferLoading } = useRequest(
    transfer,
    {
      manual: true,
      onSuccess(res) {
        console.log(res);
      },
      onError(e) {
        console.log(e);
        modal.error({
          title: 'Error',
          content: e.message,
        });
      },
    },
  );

  const RecordComp = useMemo(() => {
    console.log('RecordComp');
    console.log(deeperAccount, ethAccount, chainId);
    if (Number(chainId) !== 56) {
      return;
    }
    return (
      <BridgeRecord
        key={deeperAccount + ethAccount + chainId}
        deeperChain={deeperAccount}
        eth={ethAccount}
        chainId={chainId}
      ></BridgeRecord>
    );
  }, [deeperAccount, ethAccount, chainId]);
  return (
    <main className={styles['container']}>
      <Header white></Header>
      <div style={{ overflow: 'auto' }}>
        <div>
          <div className={styles['bridge-main']}>
            <h2 className={styles['bridge-title']}>
              <img style={{ width: 40 }} src={AdscIcon} alt='' />
              ADSC {t('Bridge')}
            </h2>

            <div className={styles['network-box']}>
              <div className={styles['from-box']}>
                <div className={styles['box-label']}>
                  <div>{t('Origin Chain')}</div>
                  {direction == 2 && <SwitchBtn></SwitchBtn>}
                </div>
                {direction == 2 ? (
                  <ETHComp></ETHComp>
                ) : (
                  <DeeperComp></DeeperComp>
                )}
              </div>
              <div className={styles['switch-box']}>
                <Button
                  shape='circle'
                  icon={<SwapOutlined></SwapOutlined>}
                  size='large'
                  onClick={() => {
                    let newDirection = direction == 1 ? 2 : 1;
                    setDirection(newDirection);
                    window.localStorage.setItem('direction', newDirection);
                  }}
                ></Button>
              </div>
              <div className={styles['to-box']}>
                <div className={styles['box-label']}>
                  {t('Destination Chain')}
                  {direction == 1 && <SwitchBtn></SwitchBtn>}
                </div>
                {direction == 1 ? (
                  <ETHComp></ETHComp>
                ) : (
                  <DeeperComp></DeeperComp>
                )}
              </div>
            </div>
            {direction === 2 && (
              <div className={styles['account-box']}>
                <div className={styles['box-title']}>
                  {network} {t(`Address`)}
                </div>
                <div className={styles['input-box']}>
                  <div
                    style={{ color: '#fff', textAlign: 'center', fontSize: 18 }}
                  >
                    {ethAccount}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      gap: 20,
                      color: '#fff',
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  >
                    <div style={{ color: '#aaa' }}>{t('Balance')}: </div>
                    <Spin spinning={runGetEthAdscBalanceLoading}>
                      <div>
                        {(Number(ethAsdcBalance) / 1e18).toFixed(5) / 1} ADSC
                      </div>
                    </Spin>
                  </div>
                </div>
              </div>
            )}
            <div className={styles['account-box']}>
              <div className={styles['box-title']}>
                Deeper Chain {t('Address')}
              </div>
              <div className={styles['input-box']}>
                <DeeperChainAccountListComp></DeeperChainAccountListComp>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    gap: 20,
                    color: '#fff',
                    marginTop: 20,
                    marginBottom: 10,
                  }}
                >
                  <div style={{ color: '#aaa' }}>{t('Balance')}: </div>
                  <Spin spinning={runGetDeeperAdscBalanceLoading}>
                    <div>
                      {(deeperAsdtBalance.balance / 1e18).toFixed(5) / 1} ADSC
                    </div>
                  </Spin>
                </div>
              </div>
            </div>
            {direction === 1 && (
              <div className={styles['account-box']}>
                <div className={styles['box-title']}>
                  {network} {t(`Address`)}
                </div>
                <div className={styles['input-box']}>
                  <div
                    style={{ color: '#fff', textAlign: 'center', fontSize: 18 }}
                  >
                    {ethAccount}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      gap: 20,
                      color: '#fff',
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  >
                    <div style={{ color: '#aaa' }}>{t('Balance')}:</div>
                    <Spin spinning={runGetEthAdscBalanceLoading}>
                      <div>
                        {(Number(ethAsdcBalance) / 1e18).toFixed(5) / 1} ADSC
                      </div>
                    </Spin>
                  </div>
                </div>
              </div>
            )}
            <div className={styles['amount-box']}>
              <div className={styles['box-title']}>{t('Quantity')}</div>
              <div className={styles['input-box']}>
                <Input
                  placeholder={t('Input transfer amount')}
                  onChange={onChangeAmount}
                  value={amount}
                  onBlur={() => {
                    if (amount > Limit.max) {
                      setAmount(Limit.max);
                    } else if (amount < Limit.min) {
                      setAmount(Limit.min);
                    }
                  }}
                  suffix={
                    <div style={{ color: '#fff' }}>
                      <img style={{ width: 30 }} src={AdscIcon} alt='' /> ADSC
                    </div>
                  }
                ></Input>
              </div>
            </div>
            <div className={styles['fee-box']}>
              <div>
                {t('Remaining amount')}: {remainingAmount} ADSC
              </div>
            </div>
            <div className={styles['transfer-box']}>
              <Button
                loading={getCurrentInfoLoading || runTransferLoading}
                onClick={() => {
                  if (chainId != 56) {
                    return switchNetwork();
                  }
                  return runTransfer();
                }}
              >
                {t('Transfer')}
              </Button>
            </div>
          </div>
        </div>
        {contextHolder}
        {deeperAccount && ethAccount && chainId && RecordComp}
      </div>
    </main>
  );
}

export default Bridge;
