import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import AdminLogin from 'containers/Admin/Login/Login';
import Admin from 'containers/Admin/Admin';
import AdminUser from 'containers/Admin/User/User';
import AdminKol from 'containers/Admin/User/Kol';
import AdminSendDPRRecord from 'containers/Admin/SendDPRRecord/SendDPRRecord';
import AdminAirdrop from 'containers/Admin/Airdrop/Airdrop';
import KolLogin from 'containers/Login/KolLogin';
import KOL from 'containers/KOL/KOL';
import KOLStaking from 'containers/KOL/Staking';
import Bridge from 'containers/Bridge/Bridge';
import ADSC from 'containers/ADSC/ADSC';
import BridgeRecord from 'containers/Admin/BridgeRecord/BridgeRecord';
import BatchQuery from 'containers/BatchQuery/BatchQuery';
import ADSCDPRBridge from 'containers/ADSCDPRBridge/ADSCDPRBridge';
import AdscDprRecord from 'containers/Admin/AdscDprRecord/AdscDprRecord';
import Transfer from 'containers/Transfer/Transfer';
import OrderRecord from 'containers/Admin/OrderRecord/OrderRecord';
import StakingCode from 'containers/Admin/StakingCode/StakingCode';
import BridgeLimit from 'containers/Admin/BridgeLimit/BridgeLimit';
import UserStakingCode from 'containers/StakingCode/StakingCode';
import DprStaking from 'containers/DprStaking/DprStaking';
import Airdrop from 'containers/Airdrop/Airdrop';

export default function Router() {
  return (
    <Routes>
      <Route path='/' element={<Navigate to='/adsc' replace={true} />} />
      <Route path='/k/login' element={<KolLogin />} />
      <Route path='/bridge' element={<Bridge />}></Route>
      <Route path='/adsc' element={<ADSC />}></Route>
      <Route path='/dpr/staking' element={<DprStaking />}></Route>
      <Route path='/stakingCode' element={<UserStakingCode />}></Route>
      <Route path='/transfer' element={<Transfer />}></Route>
      <Route path='/airdrop' element={<Airdrop />}></Route>
      <Route path='/adsc/batch' element={<BatchQuery></BatchQuery>}></Route>

      <Route path='k' element={<KOL />}>
        <Route path='staking' element={<KOLStaking />} />
      </Route>
      <Route path='adscdpr' element={<ADSCDPRBridge></ADSCDPRBridge>}></Route>
      <Route path='/admin/login' element={<AdminLogin />} />
      <Route path='admin' element={<Admin />}>
        <Route path='user' element={<AdminUser />} />
        <Route path='kol' element={<AdminKol />} />
        <Route path='sendDPRRecord' element={<AdminSendDPRRecord />} />
        <Route
          path='bridgeRecord'
          element={<BridgeRecord></BridgeRecord>}
        ></Route>
        <Route
          path='adscdprRecord'
          element={<AdscDprRecord></AdscDprRecord>}
        ></Route>
        <Route path='orderRecord' element={<OrderRecord></OrderRecord>}></Route>
        <Route path='stakingCode' element={<StakingCode></StakingCode>}></Route>
        <Route path='bridgeLimit' element={<BridgeLimit></BridgeLimit>}></Route>
        <Route path='airdrop' element={<AdminAirdrop></AdminAirdrop>}></Route>
      </Route>
    </Routes>
  );
}
