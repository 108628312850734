import React, { useState, useEffect } from 'react';

import styles from './Airdrop.module.scss';
import { Button, Input, Spin, Modal, message } from 'antd';
import Header from 'components/Header/Header';
import { useTranslation } from 'react-i18next';

import AdscIcon from 'assets/imgs/ADSC.png';
import EthApi, { signMessage } from 'w3/eth/index';
import { getADSCBalance, signStringByAddress } from 'w3/polkadot';
import { transferAdsc } from 'w3/polkadot';
import { useRequest } from 'ahooks';
import {
  getAirdropTotalTansferByAddress,
  distributeAirdrop,
} from 'apis/airdrop';
import DistributionList from './DistributionList';

const AirdropAccount = '5EnCPsHopDsDbNxsKTdyjW3HUx1Mzj4J8dwbxBAbmD4EnHsB';
const AirdropBscAccount = '0xc900F8a3086DA6bf67e8CfAB88354fA5672e7857';

function Transfer() {
  const [amount, setAmount] = useState(0);
  const [outAccount, setOutAccount] = useState(() => {
    let store = window.localStorage.getItem('AirdropOutAccount');
    if (store) {
      return store;
    }
    return '';
  });
  const [airdropAddress, setAirdropAddress] = useState('');

  const { t } = useTranslation();
  const [modal, contextHolder] = Modal.useModal();
  const [maxAmount, setMaxAmount] = useState(0);

  const {
    data: outDeeperAsdtBalance,
    run: runGetOutAccountAdscBalance,
    loading: runGetOutAccountAdscBalanceLoading,
    refresh: refreshBalance1,
  } = useRequest(
    async (address, deeperChain) => {
      if (deeperChain) {
        const balance = await getADSCBalance(address);
        return balance.balance;
      }
      const balance = await EthApi.getAdscBalance(address);
      return balance;
    },
    {
      manual: true,
      onSuccess(res) {
        console.log(res);
        setMaxAmount(res.balance / 1e18);
      },
      onError(e) {
        console.log(e);
        modal.error({
          title: 'Error',
          content: e.message,
        });
      },
    },
  );

  const {
    data: airdropTotal = {
      total: 0,
      distributionCount: 0,
    },
    run: runGetAirdropTotal,
    loading: runGetAirdropTotalLoading,
    refresh: refreshRunGetAirdropTotal,
  } = useRequest(getAirdropTotalTansferByAddress, {
    manual: true,
    onSuccess(res) {
      console.log(res);
    },
    onError(e) {
      console.log(e);
      modal.error({
        title: 'Error',
        content: e.message,
      });
    },
  });

  useEffect(() => {
    if (!outAccount) {
      return;
    }
    if (outAccount.length === 48) {
      runGetOutAccountAdscBalance(outAccount, true);
      runGetAirdropTotal(outAccount);
    } else {
      runGetOutAccountAdscBalance(outAccount);
      runGetAirdropTotal(outAccount);
    }
  }, [outAccount]);

  const onChangeAmount = (e) => {
    console.log(e.target.value);
    if (e.target.value === '') {
      setAmount('');
      return;
    }
    let value = e.target.value / 1;
    if (!value) {
      return;
    }
    setAmount(parseInt(value));
  };

  const { run: runTransfer, loading: runTransferLoading } = useRequest(
    async ({ outAccount, amount }) => {
      if (outAccount.length === 48) {
        return transferAdsc(
          outAccount,
          AirdropAccount,
          amount + '000000000000000000',
        );
      }
      return EthApi.transferAdsc(outAccount, AirdropBscAccount, amount + '');
    },
    {
      manual: true,
      onSuccess(res) {
        console.log(res);
        message.success(
          t('Transfer successfully. Please check back later for data updates'),
        );
        refreshBalance1();
      },
      onError(e) {
        message.error((e && e.message) || t('Transfer failed.'));
        console.log(e);
        modal.error({
          title: 'Error',
          content: e.message,
        });
        refreshBalance1();
      },
    },
  );

  const { run: runDistribute, loading: runDistributeLoading } = useRequest(
    async (data) => {
      const { address, target } = data;
      console.log(data);
      let deeperChainSign = '';
      let bscSign = '';
      if (address.length === 48) {
        deeperChainSign = await signStringByAddress(target, address);
      } else {
        bscSign = await signMessage(target, address);
      }
      return distributeAirdrop({
        ...data,
        deeperChainSign,
        bscSign,
        payer: address,
      });
    },
    {
      manual: true,
      onSuccess(res) {
        console.log(res);
        modal.success({
          title: 'success',
          content: t(
            'Distribute successfully. Please check back later for data updates',
          ),
        });
      },
      onError(e) {
        console.log(e);
      },
    },
  );

  return (
    <main className={styles['container']}>
      <Header white></Header>
      <div>
        <div className={styles['bridge-main']}>
          <h2 className={styles['bridge-title']}>
            <img style={{ width: 40 }} src={AdscIcon} alt='' />
            {t('Airdrop')}
          </h2>

          <div className={styles['account-box']}>
            <div className={styles['box-title']}>{t('Payment Address')}</div>
            <div className={styles['input-box']}>
              <Input
                size='large'
                placeholder={t('Please input Deeper Chain address.')}
                value={outAccount}
                onChange={(e) => {
                  setOutAccount(e.target.value);
                  window.localStorage.setItem(
                    'AirdropOutAccount',
                    e.target.value,
                  );
                }}
              ></Input>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  gap: 20,
                  color: '#fff',
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <div style={{ color: '#aaa' }}>{t('Balance')}: </div>
                <Spin spinning={runGetOutAccountAdscBalanceLoading}>
                  <div>{(outDeeperAsdtBalance / 1e18).toFixed(5) / 1} ADSC</div>
                </Spin>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  gap: 20,
                  color: '#fff',
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <div style={{ color: '#aaa' }}>{t('Transferred')}: </div>
                <Spin spinning={runGetAirdropTotalLoading}>
                  <div>{(airdropTotal.total / 1e18).toFixed(5) / 1} ADSC</div>
                </Spin>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  gap: 20,
                  color: '#fff',
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <div style={{ color: '#aaa' }}>
                  {t('Maximum airdrops can be distributed')}:{' '}
                </div>
                <Spin spinning={runGetAirdropTotalLoading}>
                  <div>
                    {Math.floor(airdropTotal.total / 1e18 / 500000)}{' '}
                    {t('Round(s)')}
                  </div>
                </Spin>
              </div>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  gap: 20,
                  color: '#fff',
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <div style={{ color: '#aaa' }}>
                  {t('Airdrops Distributed')}:{' '}
                </div>
                <Spin spinning={runGetAirdropTotalLoading}>
                  <div>
                    {airdropTotal.distributionCount} {t('Round(s)')}
                  </div>
                </Spin>
              </div>
            </div>
          </div>
          <div className={styles['amount-box']}>
            <div className={styles['box-title']}>{t('Quantity')}</div>
            <div className={styles['input-container']}>
              <div className={styles['input-box']}>
                <Input
                  placeholder={t('Input transfer amount')}
                  onChange={onChangeAmount}
                  value={amount}
                  onBlur={() => {
                    if (amount > maxAmount) {
                      setAmount(maxAmount);
                    }
                  }}
                  suffix={
                    <div style={{ color: '#fff' }}>
                      <img style={{ width: 30 }} src={AdscIcon} alt='' /> ADSC
                    </div>
                  }
                ></Input>
              </div>
            </div>
          </div>
          <div className={styles['fee-box']}>{t('AirdropTips')}</div>
          <div className={styles['transfer-box']}>
            <Button
              loading={runTransferLoading}
              disabled={!outAccount || !amount}
              onClick={() => {
                return runTransfer({
                  outAccount: outAccount,
                  amount: amount,
                });
              }}
            >
              {t('Transfer')}
            </Button>
          </div>
          <div className={styles['amount-box']}>
            <div className={styles['box-title']}>{t('Airdrop Address')}</div>
            <div className={styles['input-container']}>
              <div className={styles['input-box']}>
                <Input
                  placeholder={t('Input airdrop deeper chain address')}
                  onChange={(val) => {
                    setAirdropAddress(val.target.value);
                  }}
                  value={airdropAddress}
                ></Input>
              </div>
            </div>
          </div>
          <div className={styles['fee-box']}>{t('DistributeTips')}</div>
          <div className={styles['transfer-box']}>
            <Button
              loading={runDistributeLoading}
              disabled={
                airdropTotal.total / 1e18 / 500000 <=
                airdropTotal.distributionCount
              }
              onClick={() => {
                runDistribute({
                  address: outAccount,
                  target: airdropAddress,
                });
              }}
            >
              {t('Distribute airdrops')}
            </Button>
          </div>
        </div>
        <div>
          {outAccount && (
            <DistributionList
              key={outAccount}
              address={outAccount}
            ></DistributionList>
          )}
        </div>
      </div>
      {contextHolder}
    </main>
  );
}

export default Transfer;
