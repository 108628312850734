import axios from 'axios';
import { message } from 'antd';
import i18n from '../i18n';

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? '/dapi'
      : 'http://127.0.0.1:7001/dapi',
});

export default function dFetch(config) {
  return instance(config)
    .then((res) => res.data)
    .catch((e) => {
      console.log(i18n);
      message.error(i18n.t(`Res.${e.response.data}`));
      return Promise.reject(e);
    });
}
