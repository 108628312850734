import React, { useState } from 'react';

import styles from 'containers/Register/Register.module.scss';
import worldImg from 'assets/imgs/worldmap-bg.png';
import { Button, Checkbox, Form, Input, message } from 'antd';

import Header from 'components/Header/Header';
import { useTranslation } from 'react-i18next';
import { useRequest } from 'ahooks';
import { useNavigate, Link } from 'react-router-dom';
import kolApi from 'apis/kol';

function KolLogin() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loginStatus, setLoginStatus] = useState(0); // 0 init 1 needSetPassword 2 canLogin

  const { run: runSubmit, loading: runSubmiting } = useRequest(kolApi.login, {
    manual: true,
    onSuccess(data) {
      message.success(t('Res.Welcome') + data.tokenData.email);
      navigate('/k/staking');
    },
    onError(e) {},
  });

  const { run: runCheck, loading: checkLoading } = useRequest(
    kolApi.checkUsername,
    {
      manual: true,
      onSuccess(data) {
        console.log(data);
        if (data === 'REGISTER') {
          setLoginStatus(1);
        }
        if (data === 'LOGIN') {
          setLoginStatus(2);
        }
      },
      onError(e) {},
    },
  );

  const onFinish = (values) => {
    if (loginStatus === 1) {
      const { password, rpassword } = values;
      if (password !== rpassword) {
        return message.error(t('Confirmed passwords do not match'));
      }
      return runSubmit(values);
    }
    if (loginStatus === 2) {
      return runSubmit(values);
    }
    runCheck(values.username);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <main
      className={styles.main}
      style={{
        backgroundImage: `url(${worldImg})`,
      }}
    >
      <Header />
      <div className={styles['login-box']}>
        <div className={styles['title-box']}>
          <h2>{t('Deeper Network Staking')}</h2>
        </div>
        <Form
          name='basic'
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete='off'
        >
          <Form.Item
            label={t('Username')}
            name='username'
            rules={[
              { required: true, message: t('Please input your username!') },
            ]}
          >
            <Input />
          </Form.Item>
          {loginStatus == 1 && (
            <div>
              <Form.Item
                label={t('Password')}
                name='password'
                rules={[
                  {
                    required: true,
                    message: t('register|Please input your password!'),
                    pattern: /(?=.*[0-9])(?=.*[a-zA-Z]).{8,30}/,
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                label={t('Repeat Password')}
                name='rpassword'
                rules={[
                  {
                    required: true,
                    message: t('Please repeat your password!'),
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
            </div>
          )}
          {loginStatus == 2 && (
            <div>
              <Form.Item
                label={t('Password')}
                name='password'
                rules={[
                  {
                    required: true,
                    message: t('register|Please input your password!'),
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
            </div>
          )}

          <div className={styles['submit-btn-box']}>
            <Button
              loading={checkLoading}
              type='primary'
              block
              htmlType='submit'
            >
              {t('Login')}
            </Button>
          </div>
        </Form>
      </div>
    </main>
  );
}

export default KolLogin;
