import React, { useEffect } from 'react';
import { useRequest } from 'ahooks';
import ADSCApi from 'apis/adsc';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';

export default function RewardRecord({ deeperChain }) {
  const { t } = useTranslation();
  const {
    data: rewardRecord,
    loading: rewardRecordLoading,
    run: runGetRewardRecord,
  } = useRequest(ADSCApi.getRewardRecord, {
    manual: true,
    onSuccess(res) {
      console.log(res);
    },
  });

  useEffect(() => {
    if (deeperChain) {
      runGetRewardRecord(deeperChain);
    }
  }, [deeperChain]);

  const columns = [
    {
      title: t('Time'),
      dataIndex: 'block_datetime',
      key: 'block_datetime',
    },
    {
      title: t('Quantity'),
      dataIndex: 'attributes',
      key: 'attributes',
      render(item) {
        const amount = item.match(/"(\d+)"/);
        return `${(amount[1] / 1e18).toFixed(2) / 1} ADSC`;
      },
    },
  ];

  return (
    <div>
      <Table dataSource={rewardRecord} columns={columns} />
    </div>
  );
}
