import { AdscToken } from './ADSC/AdscToken';
const BigNumber = require('bignumber.js');
const Web3 = require('web3');
const ADSCToken = require('./ADSC/index');
const ADSCBridgeToken = require('./ADSCBridge/index');

export function getWeb3(provider) {
  if (provider) {
    return new Web3(provider);
  }
  if (window.ethereum) {
    return new Web3(window.ethereum);
  }
  return false;
}

function getNetworkId() {
  return window.ethereum.chainId / 1 || 56;
}

function getTokenContract(Token, provider, _networkId) {
  console.log(Token, provider, _networkId);
  const web3 = getWeb3(provider);
  const networkId = _networkId || getNetworkId();
  return new web3.eth.Contract(Token[networkId].abi, Token[networkId].address);
}

function getTokenData(Token) {
  return Token[getNetworkId()];
}

export async function signMessage(message, account) {
  const web3 = getWeb3();
  const signature = await web3.eth.personal.sign(message, account);
  return signature;
}

async function sendTx(tx, from) {
  const web3 = getWeb3();
  const gasPrice = await web3.eth.getGasPrice();
  const gas = await tx.estimateGas({ from });
  return tx
    .send({
      from,
      gasPrice,
      gas: parseInt(gas * 2),
    })
    .on('transactionHash', function (hash) {
      console.log('hash');
      console.log(hash);
    })
    .on('confirmation', function (confirmationNumber, receipt) {
      console.log('confirmation');
      console.log(confirmationNumber, receipt);
    })
    .on('receipt', function (receipt) {
      console.log('receipt');
      console.log(receipt);
    })
    .on('error', function (error, receipt) {
      // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
      console.log('error');
      console.log(error, receipt);
    });
}

async function switchBSC() {
  // 请求用户授权访问其 MetaMask 账户
  await window.ethereum.enable();

  // 获取当前的网络 ID
  const networkId = getNetworkId();
  // 如果当前网络不是 BSC 网络（网络 ID 为 56），则切换网络
  if (Number(networkId) !== 56) {
    await window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: '0x38', // BSC 网络的 Chain ID
          chainName: 'Smart Chain',
          nativeCurrency: {
            name: 'BNB',
            symbol: 'BNB',
            decimals: 18,
          },
          rpcUrls: ['https://bsc-dataseed.binance.org/'], // BSC 节点的 URL
          blockExplorerUrls: ['https://bscscan.com/'], // 区块浏览器的 URL
        },
      ],
    });
  }
}

async function switchETH() {
  // 请求用户授权访问其 MetaMask 账户
  await window.ethereum.enable();

  // 获取当前的网络 ID
  const networkId = getNetworkId();

  // 如果当前网络不是以太坊主网（网络 ID 为 1），则切换网络
  if (Number(networkId) !== 1) {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: '0x1' }], // 以太坊主网的 Chain ID
    });
  }
}

export async function getCurrentInfo() {
  if (!window.ethereum) {
    throw new Error('InvalidMetamask');
  }
  // 请求用户授权访问其 MetaMask 账户
  await window.ethereum.enable();
  // 读取当前钱包地址
  const accounts = await getWeb3().eth.getAccounts();
  const currentWallet = accounts[0];
  // 读取当前网络信息
  const networkId = getNetworkId();
  return {
    account: currentWallet,
    networkId: Number(networkId),
  };
}

function checkMetamask() {
  if (typeof window.ethereum !== 'undefined') {
    return true;
  } else {
    return false;
  }
}

async function getAdscBalance(address) {
  const balance = await getTokenContract(
    ADSCToken,
    'https://bsc-dataseed.binance.org/',
    56,
  )
    .methods.balanceOf(address)
    .call();
  return balance;
}

async function getBscAdscBalance(address) {
  const balance = await getTokenContract(
    ADSCToken,
    'https://bsc-dataseed1.binance.org/',
    56,
  )
    .methods.balanceOf(address)
    .call();
  return balance;
}

async function deeperChainToEth(deeperChain, eth, amount, sig) {
  console.log(deeperChain, eth, amount, sig);
  await approveBridge(eth);

  const result = await sendTx(
    getTokenContract(ADSCBridgeToken).methods.withdrawTransfer(
      deeperChain,
      eth,
      amount,
      sig,
    ),
    eth,
  );
  return result;
}

async function confirmWithdraw(messageId, bridgeSign, eth) {
  await approveBridge(eth);
  const result = await sendTx(
    getTokenContract(ADSCBridgeToken).methods.confirmWithdrawTransfer(
      messageId,
      bridgeSign,
    ),
    eth,
  );

  return result;
}

async function ethToDeeperChain(deeperChain, eth, amount) {
  await approveBridge(eth);
  const result = await sendTx(
    getTokenContract(ADSCBridgeToken).methods.setTransfer(amount, deeperChain),
    eth,
  );

  return result;
}

async function approveBridge(from) {
  const allowance = await getTokenContract(ADSCToken)
    .methods.allowance(from, getTokenData(ADSCBridgeToken).address)
    .call();
  console.log(allowance);
  if (Number(allowance) >= 1e24) {
    return;
  }

  return sendTx(
    getTokenContract(ADSCToken).methods.approve(
      getTokenData(ADSCBridgeToken).address,
      new BigNumber(1e29).toFixed(),
    ),
    from,
  );
}

async function transferAdsc(from, to, amount) {
  await getCurrentInfo();
  let adscToken = new AdscToken(getWeb3(), 56);
  const result = await adscToken.transfer(from, to, amount);

  return result;
}

export default {
  switchBSC,
  switchETH,
  getCurrentInfo,
  checkMetamask,
  getAdscBalance,
  getBscAdscBalance,
  deeperChainToEth,
  confirmWithdraw,
  ethToDeeperChain,
  approveBridge,
  transferAdsc,
};
