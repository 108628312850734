import { getStakingCodeList } from 'apis/dpr';
import { useRequest } from 'ahooks';
import { Table, ConfigProvider, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import styles from './DprStaking.module.scss';
import { IAdscDprStakingCode } from 'apis/dpr';
import { BindModal, useBindModal } from './BindModal';
export function StakingCodeList() {
  const { t } = useTranslation();

  const { data, refresh } = useRequest(getStakingCodeList, {
    onSuccess(res) {
      console.log(res);
    },
  });
  const bindModalHooks = useBindModal({
    onSuccess: () => {
      refresh();
    },
  });
  return (
    <div>
      <ConfigProvider
        theme={{
          components: {
            Table: {
              cellFontSizeSM: 12,
            },
          },
        }}
      >
        <Table<IAdscDprStakingCode>
          rowKey='_id'
          dataSource={data}
          size='small'
          columns={[
            {
              title: t('Time'),
              dataIndex: 'createdAt',
              key: 'createdAt',
              render(createdAt: string) {
                return moment(createdAt).format();
              },
            },

            {
              title: t('Staking Code'),
              dataIndex: 'code',
              key: 'code',
              width: 200,
              className: styles['break-word'],
            },
            {
              title: t('DPR Staking Quantity'),
              dataIndex: 'dprAmount',
              key: 'dprAmount',
            },
            {
              title: t('Converted DPR Staking Quantity'),
              dataIndex: 'convertedDprAmount',
              key: 'convertedDprAmount',
            },
            {
              title: t('Device'),
              dataIndex: 'sn',
              key: 'sn',
              render(sn: string, item: IAdscDprStakingCode) {
                return (
                  <div>
                    {sn ? (
                      <div>
                        <div>sn: {item.sn}</div>
                        <div>wallet: {item.deeperChain}</div>
                      </div>
                    ) : (
                      <Button
                        onClick={() => {
                          bindModalHooks.showModal(item.code);
                        }}
                      >
                        {t('Bind Device')}
                      </Button>
                    )}
                  </div>
                );
              },
            },
          ]}
        />
        <BindModal hooks={bindModalHooks}></BindModal>
      </ConfigProvider>
    </div>
  );
}
