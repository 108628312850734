import fetch from './dprFetch';

export interface ITransaction extends Document {
  address: string;
  blockNumber: string;
  timeStamp: string;
  hash: string;
  from: string;
  to: string;
  isError: string;
  txreceipt_status: string;
  input: string;
  methodId: string;
  functionName: string;
  amount: string;
  adscPrice: number;
  dprPrice: number;
  dprAmount: string;
}

export interface IAdscDprStakingCode extends Document {
  bsc: string;
  sn: string;
  deeperChain: string;
  bindingTime: number;
  code: string;
  dprAmount: number;
  convertedDprAmount: number;
}

export function getNonce(address: string) {
  return fetch({
    url: '/ethAuth/nonce',
    params: {
      address,
    },
  });
}

export function ethAuth(address: string, signature: string) {
  return fetch({
    url: '/ethAuth/login',
    method: 'POST',
    data: {
      address,
      signature,
    },
  });
}

export function getStakingRecord(coin: string) {
  return fetch({
    url: '/adscStakingCode/stakingRecord',
    params: {
      coin,
    },
  });
}

export function getAdscConvertedDprQuantity() {
  return fetch({
    url: '/adscStakingCode/adscConvertedDprQuantity',
  });
}

export function createStakingCode() {
  return fetch({
    url: '/adscStakingCode/stakingCode',
    method: 'POST',
  });
}

export function getStakingCodeList() {
  return fetch({
    url: '/adscStakingCode/stakingCode',
    method: 'GET',
  });
}

export function getStakingCodeCount() {
  return fetch({
    url: '/adscStakingCode/stakingCodeCount',
    method: 'GET',
  });
}

export function bindStakingCode(sn: string, code: string) {
  return fetch({
    url: '/adscStakingCode/bindStakingCode',
    method: 'POST',
    data: {
      sn,
      code,
    },
  });
}
