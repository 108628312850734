import React, { useState } from 'react';
import logoImg from 'assets/imgs/ADSC.png';
import { Button, Drawer } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './Header.module.scss';
import logoWhite from 'assets/imgs/ADSC.png';
import AddADSCBtn from 'components/AddADSCBtn/AddADSCBtn';
import { Link } from 'react-router-dom';
import { BarsOutlined } from '@ant-design/icons';

function Header({ white = false }) {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const changeLocale = () => {
    const currentLocale = i18n.language;
    if (currentLocale == 'en') {
      i18n.changeLanguage('zh');
      window.localStorage.setItem('locale', 'zh');
    } else {
      i18n.changeLanguage('en');
      window.localStorage.setItem('locale', 'en');
    }
  };
  const NavBox = () => (
    <div className={styles['nav-box']}>
      <div className={styles['nav-list']}>
        <div className={styles['nav-item']}>
          <Link to='/adsc'>{t('Staking')}</Link>
        </div>
        <div className={styles['nav-item']}>
          <Link to='/bridge'>{t('Bridge')}</Link>
        </div>
        <div className={styles['nav-item']}>
          <Link to='/transfer'>{t('Transfer')}</Link>
        </div>
      </div>
      <div style={{ marginRight: 20 }}>
        <AddADSCBtn></AddADSCBtn>
      </div>

      {white ? (
        <Button onClick={changeLocale} ghost>
          en / 中文
        </Button>
      ) : (
        <Button onClick={changeLocale} ghost type='primary'>
          en / 中文
        </Button>
      )}
    </div>
  );
  return (
    <header className={`${styles.header}`}>
      <div className={styles['logo-box']}>
        <img src={white ? logoWhite : logoImg} alt='' />
      </div>
      <div className={styles['pc-nav-box']}>
        <NavBox></NavBox>
      </div>
      <div className={styles['mobile-btn']}>
        <Button
          onClick={() => {
            setOpen(true);
          }}
          icon={<BarsOutlined />}
        ></Button>
      </div>
      <Drawer
        placement='left'
        width='50vw'
        closeIcon={null}
        onClose={() => {
          setOpen(false);
        }}
        open={open}
      >
        <NavBox></NavBox>
      </Drawer>
    </header>
  );
}

export default Header;
