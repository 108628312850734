import React, { useState, useEffect, useMemo } from 'react';

import styles from './Transfer.module.scss';
import { Button, Input, Spin, Modal, message } from 'antd';
import Header from 'components/Header/Header';
import { useTranslation } from 'react-i18next';
import { ArrowRightOutlined } from '@ant-design/icons';

import AdscApi from 'apis/adsc';

import BscIcon from 'assets/imgs/BNB-chain.png';
import DeeperIcon from 'assets/imgs/logo-white-sm.png';
import DPRIcon from 'assets/imgs/dpr-coin.png';
import AdscIcon from 'assets/imgs/ADSC.png';
import EthApi from 'w3/eth/index';
import { getADSCBalance } from 'w3/polkadot';
import { transferAdsc } from 'w3/polkadot';
import { useRequest } from 'ahooks';

function Transfer() {
  const [amount, setAmount] = useState(0);
  const [outAccount, setOutAccount] = useState('');
  const [inAccount, setInAccount] = useState('');

  const { t } = useTranslation();
  const [modal, contextHolder] = Modal.useModal();
  const [maxAmount, setMaxAmount] = useState(0);

  const {
    data: outDeeperAsdtBalance = { balance: 0 },
    run: runGetOutDeeperAdscBalance,
    loading: runGetOutDeeperAdscBalanceLoading,
    refresh: refreshBalance1,
  } = useRequest(getADSCBalance, {
    manual: true,
    onSuccess(res) {
      console.log(res);
      setMaxAmount(res.balance / 1e18);
    },
    onError(e) {
      console.log(e);
      modal.error({
        title: 'Error',
        content: e.message,
      });
    },
  });

  const {
    data: inDeeperAsdtBalance = { balance: 0 },
    run: runGetInDeeperAdscBalance,
    loading: runGetInDeeperAdscBalanceLoading,
    refresh: refreshBalance2,
  } = useRequest(getADSCBalance, {
    manual: true,
    onSuccess(res) {
      console.log(res);
    },
    onError(e) {
      console.log(e);
      modal.error({
        title: 'Error',
        content: e.message,
      });
    },
  });

  useEffect(() => {
    if (outAccount && outAccount.length == 48) {
      runGetOutDeeperAdscBalance(outAccount);
    }
  }, [outAccount]);

  useEffect(() => {
    if (inAccount && inAccount.length == 48) {
      runGetInDeeperAdscBalance(inAccount);
    }
  }, [inAccount]);

  const onChangeAmount = (e) => {
    console.log(e.target.value);
    if (e.target.value === '') {
      setAmount('');
      return;
    }
    let value = e.target.value / 1;
    if (!value) {
      return;
    }
    setAmount(parseInt(value));
  };

  const { run: runTransfer, loading: runTransferLoading } = useRequest(
    async ({ outAccount, inAccount, amount }) => {
      return transferAdsc(outAccount, inAccount, amount + '000000000000000000');
    },
    {
      manual: true,
      onSuccess(res) {
        console.log(res);
        message.success(t('Transfer successfully.'));
        refreshBalance1();
        refreshBalance2();
      },
      onError(e) {
        message.error((e && e.message) || t('Transfer failed.'));
        console.log(e);
        modal.error({
          title: 'Error',
          content: e.message,
        });
        refreshBalance1();
        refreshBalance2();
      },
    },
  );

  return (
    <main className={styles['container']}>
      <Header white></Header>
      <div>
        <div className={styles['bridge-main']}>
          <h2 className={styles['bridge-title']}>
            <img style={{ width: 40 }} src={AdscIcon} alt='' />
            ADSC {t('Transfer')}
          </h2>

          <div className={styles['account-box']}>
            <div className={styles['box-title']}>
              Deeper Chain {t('Transfer-out Address')}
            </div>
            <div className={styles['input-box']}>
              <Input
                size='large'
                placeholder={t('Please input transfer-out address.')}
                value={outAccount}
                onChange={(e) => {
                  setOutAccount(e.target.value);
                }}
              ></Input>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  gap: 20,
                  color: '#fff',
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <div style={{ color: '#aaa' }}>{t('Balance')}: </div>
                <Spin spinning={runGetOutDeeperAdscBalanceLoading}>
                  <div>
                    {(outDeeperAsdtBalance.balance / 1e18).toFixed(5) / 1} ADSC
                  </div>
                </Spin>
              </div>
            </div>
          </div>
          <div className={styles['account-box']}>
            <div className={styles['box-title']}>
              Deeper Chain {t('Transfer-in Address')}
            </div>
            <div className={styles['input-box']}>
              <Input
                size='large'
                placeholder={t('Please input transfer-in address.')}
                value={inAccount}
                onChange={(e) => {
                  setInAccount(e.target.value);
                }}
              ></Input>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  gap: 20,
                  color: '#fff',
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <div style={{ color: '#aaa' }}>{t('Balance')}: </div>
                <Spin spinning={runGetInDeeperAdscBalanceLoading}>
                  <div>
                    {(inDeeperAsdtBalance.balance / 1e18).toFixed(5) / 1} ADSC
                  </div>
                </Spin>
              </div>
            </div>
          </div>
          <div className={styles['amount-box']}>
            <div className={styles['box-title']}>{t('Quantity')}</div>
            <div className={styles['input-container']}>
              <div className={styles['input-box']}>
                <Input
                  placeholder={t('Input transfer amount')}
                  onChange={onChangeAmount}
                  value={amount}
                  onBlur={() => {
                    if (amount > maxAmount) {
                      setAmount(maxAmount);
                    }
                  }}
                  suffix={
                    <div style={{ color: '#fff' }}>
                      <img style={{ width: 30 }} src={AdscIcon} alt='' /> ADSC
                    </div>
                  }
                ></Input>
              </div>
            </div>
          </div>
          <div className={styles['fee-box']}></div>
          <div className={styles['transfer-box']}>
            <Button
              loading={runTransferLoading}
              disabled={!outAccount || !inAccount || !amount}
              onClick={() => {
                return runTransfer({
                  outAccount: outAccount,
                  inAccount: inAccount,
                  amount: amount,
                });
              }}
            >
              {t('Transfer')}
            </Button>
          </div>
        </div>
      </div>
      {contextHolder}
    </main>
  );
}

export default Transfer;
