import React, { useState, useRef } from 'react';
import { useRequest } from 'ahooks';
import { Button, Spin, Modal, Input, message, Table } from 'antd';
import KOLApi from 'apis/kol';
import moment from 'moment';
import styles from './KOL.module.scss';

export default function Staking() {
  const deeperChainRef = useRef();
  const searchRef = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOk = () => {
    const deeperChain = deeperChainRef.current.input.value;
    const isPolkadotAddress = /^.{48}$/.test(deeperChain);
    if (!isPolkadotAddress) {
      message.error('请输入正确的钱包地址');
      return;
    }
    runStake(deeperChain);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const {
    data = {
      limit: 0,
      staked: 0,
    },
    loading,
    run: runGetStakingInfo,
  } = useRequest(KOLApi.getStakingInfo, {});

  const { run: runStake, loading: stakeLoading } = useRequest(KOLApi.stake, {
    manual: true,
    onSuccess(res) {
      console.log(res);
      setIsModalOpen(false);
      runGetStakingInfo();
      runGetList();
    },
  });
  const {
    data: list = [],
    loading: listLoading,
    run: runGetList,
  } = useRequest(KOLApi.stakingList, {
    onSuccess(res) {
      console.log('success', res);
    },
    onError(res) {
      console.log('error', res);
    }
  });
  const onSearch = () => {
    const search = searchRef.current.input.value;
    runGetList(search);
  };
  return (
    <div>
      <Modal
        destroyOnClose
        title='请输入 Deeper Chain 钱包地址'
        loading={stakeLoading}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div>
          <Input ref={deeperChainRef}></Input>
        </div>
      </Modal>
      <Spin spinning={loading}>
        <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
          <div>可质押总量：{data.limit}</div>
          <div>已使用数量：{data.staked}</div>
          <div>剩余可质押：{data.limit - data.staked}</div>
          <div>
            <Button
              disabled={data.limit - data.staked <= 0}
              onClick={() => {
                console.log('click');
                setIsModalOpen(true);
              }}
              type='primary'
            >
              新增质押
            </Button>
          </div>
        </div>
      </Spin>
      <div style={{ height: 30 }}></div>
      <div>
        <Input.Search
          ref={searchRef}
          style={{ maxWidth: 600, width: '100%' }}
          placeholder='请输入 Deeper Chain 地址'
          allowClear
          enterButton='Search'
          onSearch={onSearch}
        ></Input.Search>
      </div>
      <div>
        <Table
          style={{ width: '100%' }}
          rowKey='_id'
          dataSource={list}
          scroll={{ x: 800 }}
          columns={[
            {
              title: 'OrderId',
              dataIndex: 'orderId',
              key: 'orderId',
              width: 100,
            },
            {
              title: 'DeeperChain',
              dataIndex: 'deeperChain',
              key: 'deeperChain',
              className: styles['text-wrap'],
              width: 100,
            },
            {
              title: '质押时间',
              dataIndex: 'createdAt',
              key: 'createdAt',
              width: 100,
              render(val) {
                return moment(val).format('YYYY-MM-DD HH:mm:ss');
              },
            },
            {
              title: '上链hash',
              dataIndex: 'syncedHash',
              key: 'syncedHash',
              className: styles['text-wrap'],
              width: 200,
            },
            {
              title: 'from',
              dataIndex: '_id',
              key: '_id',
              width: 200,
              render(_, item) {
                return (
                  <div>
                    {item.fromNftNo} - {item.fromNftRemainingCount}
                  </div>
                );
              },
            },
            {
              title: 'to',
              dataIndex: '_id',
              key: '_id',
              width: 200,
              render(_, item) {
                return (
                  <div>
                    {item.toNftNo} - {item.toNftRemainingCount}
                  </div>
                );
              },
            },
          ]}
        />
      </div>
    </div>
  );
}
