const abi = require('./abi.json');
module.exports = {
  5: {
    address: '0x4815BcD3c0e73a0C50D9438c33A9e5880Df127c8',
    abi,
  },
  56: {
    address: '0xE42C5b9aEc5d20308A0143DAB6Be609a687d3ac1',
    abi,
  },
};
