import { useRequest } from 'ahooks';
import { Modal, Input, message } from 'antd';
import { bindStakingCode } from 'apis/dpr';
import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function useBindModal({
  onSuccess = () => {},
}: {
  onSuccess: () => void;
}) {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sn, setSn] = useState('');
  const [code, setCode] = useState('');

  const { run: runBind, loading: runBindLoading } = useRequest(
    bindStakingCode,
    {
      manual: true,
      onSuccess(res) {
        console.log(res);
        if (res._id) {
          message.success(t('the binding is successful'));
          setSn('');
          setIsModalOpen(false);
          onSuccess();
        }
      },
    },
  );

  const showModal = (code: string) => {
    setCode(code);
    setIsModalOpen(true);
  };

  const handleOk = useMemo(() => {
    return () => {
      const pattern = /^Deeper-Wire-ADSC-/;
      if (!pattern.test(sn)) {
        message.error(
          t('Please input device SN.') + 'Deeper-Wire-ADSC-xxxx|xxxx',
        );
        return;
      }
      runBind(sn, code);
    };
  }, [sn, code]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return {
    isModalOpen,
    showModal,
    handleOk,
    handleCancel,
    t,
    sn,
    setSn,
    runBind,
    runBindLoading,
  };
}

export function BindModal({ hooks }: any) {
  const { isModalOpen, handleOk, handleCancel, t, sn, setSn, runBindLoading } =
    hooks;
  return (
    <Modal
      title={t('Bind Device')}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLoading={runBindLoading}
    >
      <div>
        <Input
          placeholder={
            t('Please input device SN.') + 'Deeper-Wire-ADSC-xxxx|xxxx'
          }
          allowClear
          size='large'
          onChange={(e) => {
            setSn(e.target.value);
          }}
          value={sn}
        />
      </div>
    </Modal>
  );
}
