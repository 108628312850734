import React from 'react';
import ADSCImg from 'assets/imgs/ADSC.png';
import styles from './AddADSCBtn.module.scss';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

export default function AddADSCBtn() {
  const { t } = useTranslation();
  return (
    <div
      onClick={() => {
        window.ethereum
          .request({
            method: 'wallet_watchAsset',
            params: {
              type: 'ERC20',
              options: {
                address: '0x8A7B5Fe6F43DB70affC51f3881fF79354640f3E7', // 要添加的代币合约地址
                symbol: 'ADSC', // 代币符号
                decimals: 18, // 代币小数位数
                image: 'https://www.adscredits.com/ADSC.png', // 代币图标 URL
              },
            },
          })
          .catch((e) => {
            console.log(e);
          });
      }}
      className={styles['container']}
    >
      <Button className={styles['btn-box']}>
        <img src={ADSCImg} alt='' />
        <div>{t('Add to MetaMask')}</div>
      </Button>
    </div>
  );
}
