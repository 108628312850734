import AdminApi from 'apis/admin';
import { useRequest } from 'ahooks';
import { Table, Button, Modal, message } from 'antd';
import Search from 'components/Search/Search';

function User() {
  const {
    run: getList,
    data: userList,
    loading: getListLoading,
  } = useRequest(AdminApi.getKolList, {
    onSuccess(data) {
      console.log(data);
    },
  });
  const { run: runDeleteUser, loading: deleteUserLoading } = useRequest(
    AdminApi.deleteUser,
    {
      manual: true,
      onSuccess(data) {
        console.log(data);
        getList();
      },
    },
  );
  const { run: runAddKol, loading: addLoading } = useRequest(AdminApi.addKol, {
    manual: true,
    onSuccess(data) {
      console.log(data);
      getList();
    },
  });
  const { run: runAddStakingCode, loading: addLimitLoading } = useRequest(
    AdminApi.stakingCode.create,
    {
      manual: true,
      onSuccess(data) {
        console.log(data);
        getList();
      },
    },
  );
  const { run: runLoginUser, loading: runLoginUserLoading } = useRequest(
    AdminApi.loginUser,
    {
      manual: true,
      onSuccess(data) {
        console.log(data);
        window.localStorage.setItem('token', data.token);
        window.localStorage.setItem(
          'tokenData',
          JSON.stringify(data.tokenData),
        );
        window.open('/k/staking');
      },
    },
  );
  const deleteUser = (record) => {
    Modal.confirm({
      title: `确认要删除： ${record.email} 用户吗？`,
      onOk() {
        runDeleteUser(record._id);
      },
    });
  };
  const loginUser = (record) => {
    Modal.confirm({
      title: `确认要登录： ${record.email} 用户吗？`,
      onOk() {
        runLoginUser(record._id);
      },
    });
  };
  const addKol = () => {
    let username = prompt('Username');
    if (!username) {
      return;
    }
    console.log(username);
    runAddKol(username);
  };
  const addStakingCode = (item) => {
    let quantity = prompt('Quantity');
    if (!quantity) {
      return;
    }
    if ((quantity / 1).toString() === 'NaN') {
      return message.error('Quantity Error');
    }

    runAddStakingCode({
      kolId: item._id,
      quantity,
    });
  };
  return (
    <div>
      <div style={{ marginBottom: 30, display: 'flex', gap: 20 }}>
        <Search onSearch={(val) => getList(val)} />
        <Button onClick={addKol}>Add Kol</Button>
      </div>
      <Table
        rowKey='_id'
        dataSource={userList}
        loading={getListLoading}
        columns={[
          {
            title: 'userId',
            dataIndex: '_id',
          },
          {
            title: 'username',
            dataIndex: 'username',
            render(_, item) {
              return (
                <div>
                  <div style={{ wordBreak: 'break-all' }}>{item.username}</div>
                  <div>{item.email}</div>
                </div>
              );
            },
          },

          {
            title: 'action',
            render(_, record) {
              return (
                <div>
                  <Button
                    style={{ marginRight: 20 }}
                    onClick={() => addStakingCode(record)}
                  >
                    AddStakingCode
                  </Button>
                  <Button
                    style={{ marginRight: 20 }}
                    onClick={() => loginUser(record)}
                  >
                    Login
                  </Button>
                </div>
              );
            },
          },
        ]}
      />
    </div>
  );
}

export default User;
