import { zh as ResZh } from './res';

const zh = {
  Res: {
    ...ResZh,
  },
  'Deeper Network Staking': 'Deeper Network 质押',
  Register: '注册',
  Email: '邮箱',
  Username: '用户名',
  'Verification Code': '验证码',
  Password: '密码',
  'New Password': '新密码',
  'Repeat Password': '重复密码',
  Send: '发送验证码',
  Login: '登录',
  'Reset Password': '重置密码',
  'Forget password': '忘记密码',
  Referer: '推荐人',

  'Please input your email!': '请输入邮箱',
  'Please input your username!': '请输入用户名',
  'Please input your verification code!': '请输入验证码',
  'register|Please input your password!':
    '请输入一个复杂的密码(至少8位，包含数字、字母)',
  'Please input your password!': '请输入密码',
  'invalid email': '邮箱地址不正确。',
  'Confirmed passwords do not match': '两次密码不一致。',

  Staking: '质押',
  'Create a staking': '创建质押',
  'Staking Information': '质押信息',
  'Device SN': '设备SN号',
  'Device Deeper Chain': '设备绑定的DeeperChain钱包',
  'Please bind the DeeperChain wallet on the device':
    '请在设备上绑定DeeperChain钱包',
  'Deeper Chain Staked': 'BSC质押的DeeperChain钱包',
  'USDC Staked': '质押的USDC数量',
  'Staking Type': '质押类型',
  'Current Credit': '当前信用分',
  'Credit Tier': '信用等级',
  'DPR Price': '当前DPR价格',
  'for reference only': '仅供参考，以实际为准',
  'Staking Record': '质押记录',
  Time: '时间',
  Credit: '信用分',
  Status: '状态',
  success: '成功',
  pending: '执行中',
  'Max Credit Tier': '已到最大信用等级',
  'USDC needed': '需要USDC',
  APY: '年化收益率',
  'DPR will be staked': 'DPR质押的数量',
  Pending: '执行中',
  'Add Stake': '开始质押',
  Detail: '详情',
  'Do you Want to delete this item?': '确定要删除该记录吗？',
  Delete: '删除',
  'User denied transaction signature.': '用户取消',
  Error: '出错了',
  'Please connect to the BSC mainnet': '请连接BSC主网',
  'Please install the MetaMask plugin or use this system in the mobile wallet App.':
    '请安装MetaMask插件或者在手机钱包App中使用本系统。',
  'Basic Information': '基本信息',
  'BSC Wallet Information': 'BSC 钱包信息',
  'Wallet Address': '钱包地址',
  'Please connect to the registered BEP20 address': '请连接注册的BSC钱包',
  'USDC Balance': 'USDC 余额',
  Approve: '授权',
  Stake: '质押',
  'Reset password': '重置密码',
  Caution: '请注意',
  'The Deeper Chain wallet cannot be staked with USDC, please go to the deivce backend to stake.':
    '该DeeperChain钱包无法用USDC质押，请到设备后台质押。',
  empty: '未入网',
  'Insufficient balance': '余额不足',
  'DPR daily reward': '每日收益',
  'DPR Swaped': '兑换的DPR数量',

  Wallet: '钱包',
  Search: '搜索',
  'Watch a Wallet': '导入观察钱包',
  Refresh: '刷新信息',
  'Deeper Chain Address': 'Deeper Chain 钱包地址',
  Submit: '提交',
  Cancel: '取消',
  CAMPAIGN_ID_NULL: '未入网',
  CAMPAIGN_ID_0: '黄金创世节点',
  CAMPAIGN_ID_1: '白银创世节点',
  CAMPAIGN_ID_2: 'Basic Mining 1.0',
  CAMPAIGN_ID_3: 'DPRB Mining',
  CAMPAIGN_ID_4: 'Basic Mining 2.0',
  CAMPAIGN_ID_5: 'Easy Mining',
  'Input Deeper Chain Address': '请输入 Deeper Chain 钱包地址',

  tutorial: {
    Step1: '第一步',
    Step1Content: '在设备后台创建DeeperChain钱包',
    Step2: '第二步',
    Step3: '第三步',
    Step4: '第四步',
    Step4Content: '打开BSC质押网站，注册用户',
    Step5: '第五步',
    Step5Content: '登录BSC质押网站，选择质押等级并质押',
    Step6: '第六步',
    Step6Content: '登录BSC质押网站，刷新页面检查升级情况',
  },

  'Select a Deeper Chain account': '请选择一个 Deeper Chain 钱包',
  Bridge: '跨链桥',
  'Destination Chain': '转入区块链',
  'Origin Chain': '转出区块链',
  Address: '地址',
  Balance: '余额',
  'Input transfer amount': '请输入转账数量',
  'Switch to': '切换到',
  Quantity: '数量',
  'Daily limit: 2,000,000 ADSC for each address, 2,000,000 ADSC for bridge.':
    '每日限额：每个地址 2,000,000 ADSC，整个跨链桥 2,000,000 ADSC',
  'Remaining amount': '剩余额度',
  Transfer: '转账',

  From: '转出地址',
  To: '转入地址',

  Withdraw: '提现',
  error: '错误',
  'No.': '编号',
  Description: '描述',
  'Remaining Rewards': '剩余奖励',
  'Day(s)': '天',
  "Next Day's Reward": '下一天奖励',
  'Baseline Reward': '基准奖励',
  Day: '天',
  'There is no ADSC NFT at this address.': '该地址没有ADSC NFT。',
  'Please install': '请安装',
  'and import wallet.': '并导入钱包',
  'Install polkadot{.js} extension': '请设置polkadot{.js} extension',
  'Download template file': '下载模板文件',
  'Click to Upload': '上传文件',
  'Add to MetaMask': '添加到MetaMask',

  'You will be transferred to the address below': '您将会被转账到下面的地址',

  'This wallet has no staking income and cannot cross-chain.':
    '该钱包没有质押收益，无法跨链。',
  Fee: '手续费',

  'Submitted successfully.': '提交成功!',
  'Cancel Application': '取消这笔申请',
  'Waiting to receive ADSC': '正在检查交易',
  Confirm: '确认',
  Cancelled: '已取消',
  'Are you sure you want to cancel this application?': '确认要取消这笔申请吗？',
  'Net Quantity': '到账数量',
  'Transfer failed.': '转账失败。',
  'Transferred ADSC': '已转账ADSC',
  'Exchanged DPR': '已兑换DPR',
  'Transfer-out Address': '转出地址',
  'Transfer-in Address': '转入地址',
  'Insufficient quota': '额度不足',
  'Today’s quota is no longer able to withdraw this amount of ADSC. Are you sure you want to continue to apply?':
    '今日额度已经无法提这些数量的ADSC, 确定要继续申请吗？',
  'Please input staking code.': '请输入质押码。',
  'Invalid staking code': '质押码错误',
  'Please input device SN.': '请输入设备SN号码',

  Price: '价格',
  'ADSC converted to DPR quantity': '转换成DPR数量',
  'Balance in wallet': '钱包余额',
  'Device Staking': '设备质押',
  'Total ADSC staked': 'ADSC总质押量',
  'Total DPR staked': 'DPR总质押量',
  'Total converted DPR': '转换DPR数量',
  'The count of staking codes': '质押码数量',
  'DPR used': 'DPR已使用',
  'Converted DPR used': '转换DPR已使用',
  'DPR available': '剩余DPR',
  'Converted DPR available': '剩余转换DPR',
  'Pay 50,000 DPR and 50,000 converted DPR to get a staking code':
    '花费 50,000 DPR和 50,000 转换DPR获取质押码',
  'Staking Code': '质押码',
  'DPR Staking Quantity': 'DPR 质押数量',
  'Converted DPR Staking Quantity': '转换DPR质押数量',
  Device: '设备',
  'Bind Device': '绑定设备',
  'the binding is successful': '绑定成功！',
  AirdropTips:
    '转账500,000 ADSC 可以获得一轮空投的分发。分发空投需要用户自己去分发，一个地址可以转账无穷数额的ADSC，可以分发无穷个地址。转账之后，请稍等几分钟看到转账数据和分发数据更新之后，可以进入下一步分发一个钱包。',
  Transferred: '已转账',
  'Airdrops Available': '可获得空投',
  'Round(s)': '轮',
  'Maximum airdrops can be distributed': '最多可分发空投',
  'Airdrop Address': '空投地址',
  'Input airdrop deeper chain address': '请输入Deeper Chain 获得空投的钱包地址',
  DistributeTips: '获得空投的钱包地址必须ADSC活动地址，其他地址无效。',
  'Distribute airdrops': '分发空投到该地址',
  'Airdrops Distributed': '空投已分发',
  'Transfer successfully. Please check back later for data updates':
    '转账成功，请稍后查看数据更新',
  'Payment Address': '支付钱包',
  'Distribute successfully. Please check back later for data updates':
    '分发成功，请稍后查看下面的分发列表',
  'Distribution List': '分发列表',
};

export default zh;
