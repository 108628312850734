import React from 'react';
import AsdtApi from 'apis/adsc';
import { Table, Button, Tag } from 'antd';
import {
  CheckCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { encodeDeeperChain } from 'w3/polkadot';
import EthApi from 'w3/eth/index';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
const { confirmWithdraw } = EthApi;

export default function BridgeRecord({ deeperChain, eth, chainId }) {
  const { t } = useTranslation();
  const { data: list, loading: listLoading } = useRequest(
    AsdtApi.getBridgeRecord,
    {
      defaultParams: [
        {
          deeperChain,
          eth,
          chainId,
        },
      ],
      pollingInterval: 10000,
      onSuccess(res) {
        console.log(res);
      },
    },
  );

  const { run: runConfirmWithdraw, loading: withdrawLoading } = useRequest(
    confirmWithdraw,
    {
      manual: true,
      onSuccess(res) {
        window.location.reload();
      },
    },
  );

  const columns = [
    {
      title: t('Time'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render(createdAt) {
        return moment(createdAt).format();
      },
    },
    {
      title: t('From'),
      dataIndex: 'from',
      key: 'from',
      render(_, item) {
        if (item.direction == 1) {
          return encodeDeeperChain(item.substrateSender);
        }
        if (item.direction == 2) {
          return item.sender;
        }
      },
    },
    {
      title: t('To'),
      dataIndex: 'to',
      key: 'to',
      render(_, item) {
        if (item.direction == 1) {
          return item.recipient;
        }
        if (item.direction == 2) {
          return encodeDeeperChain(item.recipient);
        }
      },
    },
    {
      title: t('Quantity'),
      dataIndex: 'amount',
      key: 'amount',
      render(amount) {
        return (amount / 1e18).toFixed(5) / 1;
      },
    },
    {
      title: t('Status'),
      dataIndex: 'status',
      key: 'status',
      render(_, item) {
        if (item.isError) {
          return (
            <Tag icon={<CloseCircleOutlined />} color='error'>
              <span
                style={{ wordBreak: 'break-all', whiteSpace: 'break-spaces' }}
              >
                {t('error')}: {item.errorMessage}
              </span>
            </Tag>
          );
        }
        if (item.bridgeSign) {
          return (
            <div>
              <Tag icon={<CheckCircleOutlined />} color='success'>
                {t('success')}
              </Tag>
              {!item.withdraw && (
                <Button
                  onClick={() => {
                    runConfirmWithdraw(item.MessageID, item.bridgeSign, eth);
                  }}
                  loading={withdrawLoading}
                  type='primary'
                  style={{ marginTop: 5 }}
                  size='small'
                >
                  {t('Withdraw')}
                </Button>
              )}
            </div>
          );
        }
        if (item.done) {
          return (
            <div>
              <Tag icon={<CheckCircleOutlined />} color='success'>
                {t('success')}
              </Tag>
            </div>
          );
        }

        return (
          <Tag icon={<SyncOutlined spin />} color='default'>
            {t('pending')}
          </Tag>
        );
      },
    },
  ];
  return (
    <div style={{ maxWidth: 1200, margin: '30px auto' }}>
      <Table rowKey='_id' dataSource={list} columns={columns} />
    </div>
  );
}
