import React, { useState, useEffect, useMemo } from 'react';

import styles from './Bridge.module.scss';
import { Button, Input, Spin, Modal, message } from 'antd';
import Header from 'components/Header/Header';
import { useTranslation } from 'react-i18next';
import { ArrowRightOutlined } from '@ant-design/icons';
import BridgeRecord from './BridgeRecord';
import { useBridgeRecord as bridgeRecordHook } from './BridgeRecord';

import AdscApi from 'apis/adsc';

import BscIcon from 'assets/imgs/BNB-chain.png';
import DeeperIcon from 'assets/imgs/logo-white-sm.png';
import DPRIcon from 'assets/imgs/dpr-coin.png';
import AdscIcon from 'assets/imgs/ADSC.png';
import EthApi from 'w3/eth/index';
import { transferAdsc } from 'w3/polkadot';
import { useRequest } from 'ahooks';
const { getBscAdscBalance, ethToDeeperChain, deeperChainToEth } = EthApi;

const Fee = 50;

function Bridge() {
  const [amount, setAmount] = useState(0);
  const [ethAccount, setEthAccount] = useState('');
  const [deeperAccount, setDeeperAccount] = useState('');
  const { t } = useTranslation();
  const [modal, contextHolder] = Modal.useModal();
  const [maxAmount, setMaxAmount] = useState(0);
  const recordHook = bridgeRecordHook();

  const { loading: getStakingInfoLoading, run: runGetStakingInfo } = useRequest(
    AdscApi.getStakingInfo,
    {
      manual: true,
      onSuccess(res) {
        console.log(res);
        if (res && res.reward) {
          let todayReward = (res.remainingCount / 365) * (res.reward / 1e18);
          let maxAmount = parseInt(todayReward / 4);
          if (maxAmount.toString() !== 'NaN') {
            setMaxAmount(maxAmount);
            setAmount(maxAmount);
            return;
          }
        }
        modal.error({
          title: 'Error',
          content: t(
            'This wallet has no staking income and cannot cross-chain.',
          ),
        });
      },
    },
  );

  const {
    data: deeperAsdtBalance = { balance: 0 },
    run: runGetDeeperAdscBalance,
    loading: runGetDeeperAdscBalanceLoading,
  } = useRequest(AdscApi.getBalance, {
    manual: true,
    onSuccess(res) {
      console.log(res);
    },
    onError(e) {
      console.log(e);
      modal.error({
        title: 'Error',
        content: e.message,
      });
    },
  });

  const {
    data: ethAsdcBalance = 0,
    run: runGetEthAdscBalance,
    loading: runGetEthAdscBalanceLoading,
  } = useRequest(getBscAdscBalance, {
    manual: true,
    onSuccess(res) {
      console.log(res);
    },
    onError(e) {
      console.log(e);
      modal.error({
        title: 'Error',
        content: e.message,
      });
    },
  });

  useEffect(() => {
    if (deeperAccount && deeperAccount.length == 48) {
      runGetDeeperAdscBalance(deeperAccount);
      runGetStakingInfo(deeperAccount);
      recordHook.runGetRecord(deeperAccount);
    }
  }, [deeperAccount]);
  useEffect(() => {
    if (ethAccount && ethAccount.length == 42) {
      runGetEthAdscBalance(ethAccount);
    }
  }, [ethAccount]);
  const ETHComp = () => (
    <div>
      <div className={styles['network-info']}>
        <div className={styles['logo-box']}>
          <img src={BscIcon} alt='' />
        </div>

        <div className={styles['network-label']}>Smart Chain</div>
      </div>
    </div>
  );
  const DeeperComp = () => (
    <div className={styles['network-info']}>
      <div className={styles['logo-box']}>
        <img src={DeeperIcon} alt='' />
      </div>
      <div className={styles['network-label']}>Deeper Chain</div>
    </div>
  );
  const SwitchBtn = () => <div></div>;

  const onChangeAmount = (e) => {
    if (e.target.value === '') {
      setAmount('');
      return;
    }
    let value = e.target.value / 1;
    if (!value) {
      return;
    }
    setAmount(parseInt(value));
  };

  const { run: runTransfer, loading: runTransferLoading } = useRequest(
    async ({ deeperChain, bsc, amount }) => {
      let haveRecord = await AdscApi.checkHaveRecord(deeperChain)
        .then((res) => {
          return false;
        })
        .catch((e) => {
          return true;
        });
      if (haveRecord) {
        return;
      }

      let hash = await transferAdsc(deeperChain, amount + '000000000000000000');
      console.log(hash);
      if (!hash) {
        message.error(t('Transfer failed.'));
        return;
      }
      return AdscApi.submitAdscToDpr({
        deeperChain,
        bsc,
        amount,
        hash: hash.split('-')[0],
      });
    },
    {
      manual: true,
      onSuccess(res) {
        console.log(res);
        if (res && res._id) {
          message.success(t('Submitted successfully.'));
          recordHook.runGetRecord(deeperAccount);
        }
      },
      onError(e) {
        message.error((e && e.message) || t('Transfer failed.'));
        console.log(e);
      },
    },
  );

  return (
    <main className={styles['container']}>
      <Header white></Header>
      <div>
        <div className={styles['bridge-main']}>
          <h2 className={styles['bridge-title']}>
            <img style={{ width: 40 }} src={AdscIcon} alt='' />
            ADSC <ArrowRightOutlined /> DPR {t('Bridge')}
          </h2>

          <div className={styles['network-box']}>
            <div className={styles['from-box']}>
              <div className={styles['box-label']}>
                <div>{t('Origin Chain')}</div>
              </div>
              <DeeperComp></DeeperComp>
            </div>

            <div className={styles['to-box']}>
              <div className={styles['box-label']}>
                {t('Destination Chain')}
                <SwitchBtn></SwitchBtn>
              </div>
              <ETHComp></ETHComp>
            </div>
          </div>

          <div className={styles['account-box']}>
            <div className={styles['box-title']}>
              Deeper Chain {t('Address')}
            </div>
            <div className={styles['input-box']}>
              <Input
                size='large'
                placeholder={t(
                  'Please input your deeper chain wallet address.',
                )}
                value={deeperAccount}
                onChange={(e) => {
                  setDeeperAccount(e.target.value);
                }}
              ></Input>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  gap: 20,
                  color: '#fff',
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <div style={{ color: '#aaa' }}>{t('Balance')}: </div>
                <Spin spinning={runGetDeeperAdscBalanceLoading}>
                  <div>
                    {(deeperAsdtBalance.balance / 1e18).toFixed(5) / 1} ADSC
                  </div>
                </Spin>
              </div>
            </div>
          </div>

          <div className={styles['account-box']}>
            <div className={styles['box-title']}>BSC {t(`Address`)}</div>
            <div className={styles['input-box']}>
              <div style={{ color: '#fff', textAlign: 'center', fontSize: 18 }}>
                <Input
                  size='large'
                  placeholder={t('Please input your bsc wallet address.')}
                  value={ethAccount}
                  onChange={(e) => {
                    setEthAccount(e.target.value);
                  }}
                ></Input>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  gap: 20,
                  color: '#fff',
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <div style={{ color: '#aaa' }}>{t('Balance')}:</div>
                <Spin spinning={runGetEthAdscBalanceLoading}>
                  <div>
                    {(Number(ethAsdcBalance) / 1e18).toFixed(5) / 1} ADSC
                  </div>
                </Spin>
              </div>
            </div>
          </div>
          <div className={styles['amount-box']}>
            <div className={styles['box-title']}>{t('Quantity')}</div>
            <div className={styles['input-container']}>
              <div className={styles['input-box']}>
                <Input
                  placeholder={t('Input transfer amount')}
                  onChange={onChangeAmount}
                  value={amount}
                  onBlur={() => {
                    if (amount > maxAmount) {
                      setAmount(maxAmount);
                    }
                    if (amount < Fee) {
                      setAmount(0);
                    }
                  }}
                  suffix={
                    <div style={{ color: '#fff' }}>
                      <img style={{ width: 30 }} src={AdscIcon} alt='' /> ADSC
                    </div>
                  }
                ></Input>
              </div>
              <div
                style={{
                  width: 150,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  color: '#fff',
                  fontSize: 20,
                }}
              >
                <div style={{ fontSize: 12 }}>{t('Fee')}: 50 ADSC</div>
                <div>
                  2{' '}
                  <ArrowRightOutlined
                    style={{ fontSize: 30, margin: '0 10px' }}
                  />{' '}
                  1
                </div>
              </div>
              <div className={styles['input-box']}>
                <Input
                  placeholder={t('Input transfer amount')}
                  value={amount > 50 ? ((amount - 50) / 2).toFixed(5) / 1 : 0}
                  suffix={
                    <div style={{ color: '#fff' }}>
                      <img style={{ width: 20 }} src={DPRIcon} alt='' /> DPR
                    </div>
                  }
                ></Input>
              </div>
            </div>
          </div>
          <div className={styles['fee-box']}>
            {t('You will be transferred to the address below')}
            <div style={{ color: '#fff', fontSize: 18, marginTop: 10 }}>
              5HGgiZs3zGq4qXgMd5CBwqmVFZexsUmfYBVFxDNG78RzYAS4
            </div>
          </div>
          <div className={styles['transfer-box']}>
            <Button
              loading={runTransferLoading || getStakingInfoLoading}
              disabled={!deeperAccount || !ethAccount || !amount}
              onClick={() => {
                return runTransfer({
                  deeperChain: deeperAccount,
                  bsc: ethAccount,
                  amount: amount,
                });
              }}
            >
              {t('Submit')}
            </Button>
          </div>
        </div>
      </div>
      {contextHolder}
      <BridgeRecord theHook={recordHook}></BridgeRecord>
    </main>
  );
}

export default Bridge;
