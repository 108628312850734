import { getStakingRecord } from 'apis/dpr';
import { useRequest } from 'ahooks';
import { Table, ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import styles from './DprStaking.module.scss';
import { ITransaction } from 'apis/dpr';
export function StakingRecord({ coin }: { coin: string }) {
  const { t } = useTranslation();
  const { data } = useRequest(getStakingRecord, {
    defaultParams: [coin],
    onSuccess(res) {
      console.log(res);
    },
  });
  const priceColumns =
    coin === 'ADSC'
      ? [
          {
            title: t('Price'),
            dataIndex: 'dprPrice',
            key: 'dprPrice',
            render(_: any, item: ITransaction) {
              return (
                <div>
                  <div>
                    ADSC {t('Price')}: {item.adscPrice.toFixed(6)} USDT/ADSC
                  </div>
                  <div>
                    DPR {t('Price')}: {item.dprPrice.toFixed(6)} USDT/DPR
                  </div>
                </div>
              );
            },
          },
          {
            title: t('ADSC converted to DPR quantity'),
            dataIndex: 'dprPrice',
            key: 'dprPrice',
            render(_: any, item: ITransaction) {
              return <div>{item.dprAmount} DPR</div>;
            },
          },
        ]
      : [];
  return (
    <div>
      <ConfigProvider
        theme={{
          components: {
            Table: {
              cellFontSizeSM: 12,
            },
          },
        }}
      >
        <Table<ITransaction>
          rowKey='_id'
          dataSource={data}
          size='small'
          columns={[
            {
              title: t('Time'),
              dataIndex: 'timeStamp',
              key: 'timeStamp',
              render(timeStamp: string) {
                return moment(Number(timeStamp) * 1000).format();
              },
            },

            {
              title: t('Hash'),
              dataIndex: 'hash',
              key: 'hash',
              width: 200,
              className: styles['break-word'],
            },
            {
              title: t('Quantity'),
              dataIndex: 'amount',
              key: 'amount',
              render(amount: string) {
                return +(Number(amount) / 1e18).toFixed(4) + ' ' + coin;
              },
            },
            ...priceColumns,
          ]}
        />
      </ConfigProvider>
      ;
    </div>
  );
}
