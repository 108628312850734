import React, { useState } from 'react';
import { useRequest } from 'ahooks';
import AdminApi from 'apis/admin';
import { Table, Button, Modal, Form, Input } from 'antd';
import moment from 'moment';
import Search from 'components/Search/Search';

export default function OrderRecord() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [form] = Form.useForm()

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onFinish = ({
        orderId,
        quantity,
        bsc,
        kol,
    }) => {
        setIsModalOpen(false);
        if (!orderId && !quantity && !bsc && !kol) {
            return
        }
        runCreateOrder({
            orderId,
            quantity,
            bsc,
            kol,
        })
        setIsModalOpen(false);
        console.log('Success:', {
            orderId,
            quantity,
            bsc,
            kol,
        });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const {
        run: getList,
        data: list,
        loading: getLoading,
    } = useRequest(AdminApi.getOrderlist, {
        onSuccess(data) {
            console.log(data);
        },
    });

    const { run: runCreateOrder, loading: addLoading } = useRequest(AdminApi.createOrderRecord, {
        manual: true,
        onSuccess(data) {
            console.log(data);
            getList();
        },
    });

    const { run: runDeleteOrder, loading: delLoading } = useRequest(AdminApi.delOrderRecord, {
        manual: true,
        onSuccess(data) {
            console.log(data);
            getList();
        },
    });


    const delOrder = (record) => {
        Modal.confirm({
            title: `确认要删除吗？`,
            onOk() {
                runDeleteOrder(record._id);
            },
        });
    }

    const columns = [
        {
            title: 'Id',
            dataIndex: '_id',
        },
        {
            title: 'orderId',
            dataIndex: 'orderId',
        },
        {
            title: 'bsc',
            dataIndex: 'bsc',
        },
        {
            title: 'quantity',
            dataIndex: 'quantity',
        },
        {
            title: 'kol',
            dataIndex: 'kol',
        },
        {
            title: 'createdAt',
            dataIndex: 'createdAt',
            render(val) {
                return moment(val).format('YYYY-MM-DD HH:mm:ss');
            },
        },
        {
            title: 'action',
            render(_, record) {
                return (
                    <div>
                        <Button onClick={() => delOrder(record)} danger>
                            Delete
                        </Button>
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <div style={{ marginBottom: 30, display: 'flex', gap: 20 }}>
                <Search onSearch={(val) => getList(val)} />
                <Button onClick={showModal}>Create Oreder</Button>
            </div>

            <Modal title="Create Oreder" open={isModalOpen} onOk={() => form.submit()} onCancel={handleCancel}>
                <Form
                    form={form}
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    style={{
                        maxWidth: 600,
                    }}
                    initialValues={{
                        remember: false,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="orderId"
                        name="orderId"
                        rules={[
                            {
                                required: true,
                                message: 'Please input orderId!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="bsc"
                        name="bsc"
                        rules={[
                            {
                                required: true,
                                message: 'Please input bsc!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="quantity"
                        name="quantity"
                        rules={[
                            {
                                required: true,
                                message: 'Please input quantity!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="kol"
                        name="kol"
                        rules={[
                            {
                                required: true,
                                message: 'Please input kol!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                </Form>
            </Modal>

            <Table rowKey='_id' dataSource={list} columns={columns} />
        </>
    );
}
