import { ApiPromise, WsProvider } from '@polkadot/api';

const {
  web3Accounts,
  web3Enable,
  web3FromSource,
  web3FromAddress,
} = require('@polkadot/extension-dapp');
const { u8aToHex, hexToU8a, stringToHex } = require('@polkadot/util');
const {
  encodeAddress,
  decodeAddress,
  signatureVerify,
} = require('@polkadot/util-crypto');

const sleep = (t) => new Promise((r) => setTimeout(r, t));
const deeperChainWss = 'wss://mainnet-deeper-chain.deeper.network';
// process.env.NODE_ENV == 'production'
//   ? 'wss://mainnet-deeper-chain.deeper.network'
//   : 'wss://mainnet-dev.deeper.network/';

let globalApi;

async function getApi() {
  if (globalApi && globalApi.isConnected) {
    return globalApi;
  }
  if (globalApi) {
    await globalApi.disconnect();
  }

  const wsProvider = new WsProvider(deeperChainWss);
  globalApi = await ApiPromise.create({ provider: wsProvider });
  return globalApi;
}

export async function getPolkadotAccountList(lastTryTimes = 0) {
  let tryTimes = lastTryTimes + 1;
  try {
    // 启用 Polkadot.js 扩展程序
    await web3Enable('DeeperChain Bridge');
    // 获取所有钱包信息
    const accounts = await web3Accounts();
    if (accounts.length == 0 && tryTimes <= 5) {
      await sleep(3000);
      return getPolkadotAccountList(tryTimes);
    }
    return accounts;
  } catch (error) {
    console.log(error);
  }
}

export function checkPolkadotjs() {
  if (
    typeof window.injectedWeb3 !== 'undefined' &&
    window.injectedWeb3['polkadot-js']
  ) {
    return true;
  } else {
    return false;
  }
}

// 创建签名函数
export async function signString(message, account) {
  // 使用 Polkadot.js 扩展进行签名
  const injector = await web3FromSource(account.meta.source);
  const signRaw = injector?.signer?.signRaw;

  if (!!signRaw) {
    // after making sure that signRaw is defined
    // we can use it to sign our message
    const { signature } = await signRaw({
      address: account.address,
      data: stringToHex(message),
      type: 'bytes',
    });
    return signature;
  }
}

export async function signStringByAddress(message, account) {
  await web3Enable('ADSC');
  console.log(message, account);
  const injector = await web3FromAddress(account);
  const signRaw = injector?.signer?.signRaw;

  if (!!signRaw) {
    // after making sure that signRaw is defined
    // we can use it to sign our message
    const { signature } = await signRaw({
      address: account,
      data: stringToHex(message),
      type: 'bytes',
    });
    return signature;
  }
}

export function encodeDeeperChain(address) {
  return encodeAddress(hexToU8a(address));
}
export function decodeDeeperChain(address) {
  return u8aToHex(decodeAddress(address));
}

export async function getADSCBalance(deeperChain) {
  const api = await getApi();
  const balance = await api.query.assets.account(0, deeperChain);
  return balance.toJSON() || { balance: 0 };
}

export async function getImOnline(deeperChain) {
  const diffBlock = 17280;
  const api = await getApi();
  let lastBlock = await api.query.system.number();
  lastBlock = lastBlock.toJSON();
  let addressBlock = await api.query.deeperNode.imOnline(deeperChain);
  addressBlock = addressBlock.toJSON();
  console.log(lastBlock, addressBlock);
  if (lastBlock - addressBlock > diffBlock) {
    return false;
  }
  return true;
}

export async function getDPRBalance(deeperChain) {
  const api = await getApi();
  const balance = await api.query.system.account(deeperChain);
  return balance.toJSON();
}

export async function getAdscNft(deeperChain) {
  const api = await getApi();
  let nftList = await api.query.uniques.instanceMetadataOf.entries(4);
  let nftMap = {};
  nftList.forEach((it) => {
    let key = it[0].toHuman();
    let data = it[1].toHuman();
    nftMap[key.join('_')] = data.data;
  });
  let userNftList = await api.query.uniques.account.entries(deeperChain, 4);
  userNftList = userNftList.map((it) => {
    let data = it[0].toHuman();
    let key = data.slice(1, 3).join('_');

    return {
      nftNo: key,
      asdtNft: data[2],
      nft: nftMap[key] ? JSON.parse(nftMap[key]) : null,
    };
  });
  return userNftList;
}

export async function getAdscStakingByAddress(deeperChain) {
  const api = await getApi();

  let nft = await api.query.adsc.adscNfts(deeperChain);
  nft = nft.toJSON();
  if (!nft) {
    return;
  }
  let remainingCount = await api.query.adsc.adscStakers(deeperChain);
  remainingCount = remainingCount.toJSON();
  return {
    nft,
    remainingCount,
  };
}

export async function getAdscStakingInfo(deeperChain) {
  const api = await getApi();

  let stakingInfo = await getAdscStakingByAddress(deeperChain);
  if (!stakingInfo) {
    return;
  }
  const { nft, remainingCount } = stakingInfo;
  let reward = await api.query.adsc.currentAdscBaseReward();
  reward = reward.toJSON();
  let halfTarget = await api.query.adsc.currentHalfTarget();
  halfTarget = halfTarget.toJSON();
  return {
    nftNo: nft[1],
    nftData: '',
    remainingCount,
    reward,
    halfTarget,
  };
}

function getDispatchError(dispatchError) {
  let message = dispatchError.type;

  if (dispatchError.isModule) {
    try {
      const mod = dispatchError.asModule;
      const error = dispatchError.registry.findMetaError(mod);

      message = `${error.section}.${error.name}`;
    } catch (error) {
      // swallow
    }
  } else if (dispatchError.isToken) {
    message = `${dispatchError.type}.${dispatchError.asToken.type}`;
  }

  return message;
}

async function send(setTx) {
  const api = await getApi();
  return new Promise(async (resolve, reject) => {
    try {
      let unsub = await setTx.send(({ status, events }) => {
        let hash = '';

        if (status.isFinalized || status.isInBlock) {
          events
            .filter(({ event: { section } }) => section === 'system')
            .forEach(
              ({
                event: {
                  method,
                  data: [result],
                },
              }) => {
                if (result.isError) {
                  const error = result.asError;
                  if (error.isModule) {
                    // for module errors, we have the section indexed, lookup
                    const decoded = api.registry.findMetaError(error.asModule);
                    const { docs, name, section } = decoded;
                    console.error(`${section}.${name}: ${docs.join(' ')}`);
                    reject(`${section}.${name}: ${docs.join(' ')}`);
                  } else {
                    // Other, CannotLookup, BadOrigin, no extra info
                    console.error(error.toString());
                    reject(error.toString());
                  }
                }
                if (method === 'ExtrinsicFailed') {
                  // let dispatchError = getDispatchError(data.dispatchError);
                  // console.log(dispatchError);
                  reject({ message: 'ExtrinsicFailed' });
                } else if (method === 'ExtrinsicSuccess') {
                }
              },
            );
        }

        if (status.isFinalized) {
          hash = status.asFinalized.toString() + '-' + setTx.hash.toHex();
          resolve(hash);
          unsub();
        }
      });
    } catch (e) {
      console.log(e);
    }
  });
}

export async function transferAdsc(outAccount, inAccount, amount) {
  console.log('transferAdsc');
  const api = await getApi();
  await web3Enable('ADSC');
  const injector = await web3FromAddress(outAccount);
  const setTx = await api.tx.assets
    .transfer(0, inAccount, amount)
    .signAsync(outAccount, { signer: injector.signer });
  return send(setTx);
}
