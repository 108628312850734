import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useRequest } from 'ahooks';
import Header from 'components/Header/Header';
import worldImg from 'assets/imgs/worldmap-bg.png';

import styles from './ADSC.module.scss';
import { Select, Spin, Card, Modal, Button, Input } from 'antd';
import { SwapOutlined } from '@ant-design/icons';
import ADSCApi from 'apis/adsc';
import { getPolkadotAccountList } from 'w3/polkadot';
import RewardRecord from './RewardRecord';
import { useSearchParams } from 'react-router-dom';

function ADSC() {
  const { t } = useTranslation();
  const [init, setInit] = useState(false);
  const [nextDayReward, setNextDayReward] = useState(0);
  const [deeperAccount, setDeeperAccount] = useState('');
  const [modal, contextHolder] = Modal.useModal();
  const [searchParams] = useSearchParams();
  const searchAddress = searchParams.get('deeperChain');
  console.log(searchAddress);

  // const {
  //   data: deeperChainAccountList = [],
  //   loading: getDeeperChainAccountLoading,
  // } = useRequest(getPolkadotAccountList, {
  //   onSuccess(res) {
  //     if (res.length == 0) {
  //       modal.confirm({
  //         title: t('Install polkadot{.js} extension'),
  //         content: (
  //           <div>
  //             {t('Please install')}
  //             <a
  //               target='_blank'
  //               style={{ display: 'inline-block', margin: '0 10px' }}
  //               href='https://chrome.google.com/webstore/detail/polkadot%7Bjs%7D-extension/mopnmbcafieddcagagdcbnhejhlodfdd'
  //             >
  //               polkadot{`{.js}`} extension
  //             </a>
  //             {t('and import wallet.')}
  //           </div>
  //         ),
  //         onOk: () => {
  //           window.location.reload();
  //         },
  //       });
  //       return;
  //     }
  //     if (res[0]) {
  //       setDeeperAccount(res[0].address);
  //     }
  //   },
  //   onError(e) {
  //     console.log(e);
  //     modal.error({
  //       title: 'Error',
  //       content: e.message,
  //     });
  //   },
  // });

  const {
    data: stakingInfo = {},
    run: runGetStakingInfo,
    loading: runGetStakingInfoLoading,
  } = useRequest(ADSCApi.getStakingInfo, {
    manual: true,
    onSuccess(res) {
      console.log(res);
    },
    onFinally() {
      setInit(true);
    },
  });
  const {
    data: balanceInfo = { balance: 0 },
    run: runGetBalance,
    loading: runGetBalanceLoading,
  } = useRequest(ADSCApi.getBalance, {
    manual: true,
    onSuccess(res) {
      console.log(res);
    },
  });

  useEffect(() => {
    if (stakingInfo && stakingInfo.reward) {
      let nextDayR =
        (stakingInfo.reward / 1e18 / 365) * stakingInfo.remainingCount;
      setNextDayReward(nextDayR.toFixed(5) / 1);
    }
  }, [stakingInfo]);

  useEffect(() => {
    console.log('deeperAccount changed');
    if (deeperAccount && deeperAccount.length == 48) {
      runGetBalance(deeperAccount);
      runGetStakingInfo(deeperAccount);
    }
  }, [deeperAccount]);

  useEffect(() => {
    if (searchAddress) {
      setDeeperAccount(searchAddress);
    }
  }, [searchAddress]);

  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: `url(${worldImg})`,
      }}
    >
      <Header></Header>
      <div style={{ height: 20 }}></div>
      <div className={styles['search-box']}>
        <Input.Search
          placeholder='input search text'
          allowClear
          size='large'
          onChange={(e) => {
            setDeeperAccount(e.target.value);
          }}
          value={deeperAccount}
          onSearch={(val) => {
            setDeeperAccount(val);
          }}
        />
      </div>
      {deeperAccount && (
        <div className={styles['card-container']}>
          <div className={styles['card-box']}>
            <Card title={t('Balance')}>
              <Spin spinning={runGetBalanceLoading}>
                <div style={{ textAlign: 'center', fontSize: 18 }}>
                  {(balanceInfo.balance / 1e18).toFixed(2) / 1} ADSC
                </div>
                {/* <div className={styles['adsc-link-btn-box']}>
                  <Button>
                    ADSC <SwapOutlined /> DPR
                  </Button>
                  <Button>
                    Deeper Chain <SwapOutlined /> BSC
                  </Button>
                </div> */}
              </Spin>
            </Card>
            <div style={{ height: 30, textAlign: 'center', marginTop: 50 }}>
              <Spin spinning={runGetStakingInfoLoading}></Spin>
            </div>
            {!init ? (
              ''
            ) : stakingInfo.nftNo !== undefined ? (
              <React.Fragment>
                <Card title={t('Staking Information')} bordered={false}>
                  <div className={styles['card-content']}>
                    <div className={styles['nft-img-box']}>
                      <img src={stakingInfo.nftData?.imageFile} alt='' />
                    </div>
                    <div>
                      <div className={styles['info-row']}>
                        <div className={styles['info-title']}>
                          {' '}
                          NFT {t('No.')}
                        </div>
                        <div className={styles['info-value']}>
                          {stakingInfo.nftNo}
                        </div>
                      </div>

                      <div className={styles['info-row']}>
                        <div className={styles['info-title']}>
                          {' '}
                          NFT {t('Name')}
                        </div>
                        <div className={styles['info-value']}>
                          {stakingInfo.nftData?.name}
                        </div>
                      </div>

                      <div className={styles['info-row']}>
                        <div className={styles['info-title']}>
                          NFT {t('Description')}
                        </div>
                        <div className={styles['info-value']}>
                          {stakingInfo.nftData?.description}
                        </div>
                      </div>
                      <div className={styles['info-row']}>
                        <div className={styles['info-title']}>
                          {t('Remaining Rewards')}
                        </div>
                        <div className={styles['info-value']}>
                          {stakingInfo.remainingCount} {t('Day(s)')}
                        </div>
                      </div>
                      <div className={styles['info-row']}>
                        <div className={styles['info-title']}>
                          {t('Baseline Reward')}
                        </div>
                        <div className={styles['info-value']}>
                          {stakingInfo.reward / (1e18).toFixed(5) / 1} ADSC/
                          {t('Day')}
                        </div>
                      </div>
                      <div className={styles['info-row']}>
                        <div className={styles['info-title']}>
                          {t("Next Day's Reward")}
                        </div>
                        <div className={styles['info-value']}>
                          {nextDayReward} ADSC
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
                <div className={styles['reward-list']}>
                  <RewardRecord deeperChain={deeperAccount}></RewardRecord>
                </div>
              </React.Fragment>
            ) : (
              <Card title={t('Staking Information')} bordered={false}>
                <div className={styles['card-content']}>
                  {t('There is no ADSC NFT at this address.')}
                </div>
              </Card>
            )}
          </div>
        </div>
      )}

      <div style={{ height: '100px' }}></div>
      {contextHolder}
    </div>
  );
}

export default ADSC;
