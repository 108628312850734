import React from 'react';
import { useRequest } from 'ahooks';
import AdminApi from 'apis/admin';
import { Table, Tag, Button } from 'antd';
import { encodeDeeperChain } from 'w3/polkadot';
import moment from 'moment';
import styles from './AdscDprRecord.module.scss';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

export default function AdscDprRecord() {
  const {
    data: list,
    loading: listLoading,
    run: runGetList,
  } = useRequest(AdminApi.adscDprRecord, {
    onSuccess(res) {
      console.log(res);
    },
  });

  const { loading: setHashLoading, run: runSetHash } = useRequest(
    AdminApi.setExchangedHash,
    {
      manual: true,
      onSuccess(res) {
        console.log(res);
        runGetList();
      },
    },
  );

  const columns = [
    {
      title: 'time',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render(time) {
        return moment(time).format();
      },
    },
    {
      title: 'deeperChain',
      dataIndex: 'deeperChain',
      key: 'deeperChain',
      className: styles['break-word'],
      width: 200,
    },
    {
      title: 'bsc',
      dataIndex: 'bsc',
      key: 'bsc',
      className: styles['break-word'],
      width: 200,
    },
    {
      title: 'maxAmount(ADSC)',
      dataIndex: 'maxAmount',
      key: 'maxAmount',
    },
    {
      title: 'amount(ADSC)',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'fee(ADSC)',
      dataIndex: 'fee',
      key: 'fee',
    },
    {
      title: 'needToSend(DPR)',
      dataIndex: 'amount',
      key: 'amount',
      render(_, item) {
        return (item.amount - item.fee) / 2;
      },
    },
    {
      title: 'transferredHash',
      dataIndex: 'transferredHash',
      key: 'transferredHash',
      className: styles['break-word'],
    },

    {
      title: 'exchangedHash',
      dataIndex: 'exchangedHash',
      key: 'exchangedHash',
      width: 200,
      className: styles['break-word'],
    },

    {
      title: 'status',
      dataIndex: 'status',
      key: 'status',
      render(status, item) {
        const map = ['init', 'transferred', 'exchanged', 'canceled'];
        const colorList = ['default', 'processing', 'success', 'default'];
        return (
          <div>
            <Tag color={colorList[status]}>{map[status]}</Tag>
            {status == 1 && (
              <div style={{ margin: 5 }}>
                <Button
                  size='small'
                  type='primary'
                  onClick={() => {
                    const hash = prompt('hash');
                    if (!hash) {
                      return;
                    }
                    console.log(hash);
                    runSetHash({ id: item._id, hash });
                  }}
                >
                  Set Exchanged Hash
                </Button>
              </div>
            )}
          </div>
        );
      },
    },
  ];

  return <Table rowKey='_id' dataSource={list} columns={columns} />;
}
