import ofetch from './fetch';

const fetch = (config: any) => {
  config.isAdmin = true;
  return ofetch(config);
};

function login(data: any) {
  return fetch({
    url: '/admin/login',
    method: 'POST',
    data,
  }).then((res) => {
    if (res) {
      window.localStorage.setItem('adminToken', res.token);
      window.localStorage.setItem(
        'adminTokenData',
        JSON.stringify(res.tokenData),
      );
    }
    return res;
  });
}

function getUserList(search: string) {
  return fetch({
    url: '/admin/user',
    method: 'GET',
    params: {
      search,
    },
  });
}

function getKolList(search: string) {
  return fetch({
    url: '/admin/user/kol',
    method: 'GET',
    params: {
      search,
    },
  });
}

function addKol(username: string) {
  return fetch({
    url: '/admin/user/kol',
    method: 'POST',
    data: {
      username,
    },
  });
}

function deleteUser(id: string) {
  return fetch({
    url: '/admin/user',
    method: 'DELETE',
    params: {
      id,
    },
  });
}

function loginUser(id: string) {
  return fetch({
    url: '/admin/userLogin',
    method: 'POST',
    params: {
      id,
    },
  });
}

function getStakingList(search: string) {
  return fetch({
    url: '/admin/staking',
    method: 'GET',
    params: {
      search,
    },
  });
}

function deleteStaking(id: string) {
  return fetch({
    url: '/admin/staking',
    method: 'DELETE',
    params: {
      id,
    },
  });
}

function getStakingRecord(search: string) {
  return fetch({
    url: '/admin/stakingRecord',
    method: 'GET',
    params: {
      search,
    },
  });
}

function deleteStakingRecord(id: string) {
  return fetch({
    url: '/admin/stakingRecord',
    method: 'DELETE',
    params: {
      id,
    },
  });
}

function checkSNOpen(sn: string) {
  return fetch({
    url: '/admin/checkSNOpen',
    method: 'GET',
    params: {
      sn,
    },
  });
}

function getReferer(search: string) {
  return fetch({
    url: '/admin/referer',
    method: 'GET',
    params: {
      search,
    },
  });
}

function getSendDPRRecord(search: string) {
  return fetch({
    url: '/admin/sendDPRRecord',
    method: 'GET',
    params: {
      search,
    },
  });
}

function deleteSendDPRRecord(id: string) {
  return fetch({
    url: '/admin/sendDPRRecord',
    method: 'DELETE',
    params: {
      id,
    },
  });
}

function bridgeRecordBsc(search: string) {
  return fetch({
    url: '/admin/bridgeRecordBsc',
    method: 'GET',
    params: {
      search,
    },
  });
}

function adscDprRecord(search: string) {
  return fetch({
    url: '/admin/adscDprRecord',
    method: 'GET',
    params: {
      search,
    },
  });
}

function setExchangedHash({ id, hash }: { id: string; hash: string }) {
  return fetch({
    url: '/admin/setExchangedHash',
    method: 'POST',
    data: {
      id,
      hash,
    },
  });
}

function createOrderRecord({
  orderId,
  quantity,
  bsc,
  kol,
}: {
  orderId: string;
  quantity: number;
  bsc: string;
  kol: string;
}) {
  return fetch({
    url: '/admin/orderRecord',
    method: 'POST',
    data: {
      orderId,
      quantity,
      bsc,
      kol,
    },
  });
}

function getOrderlist(search: string) {
  return fetch({
    url: '/admin/orderRecord',
    method: 'GET',
    params: {
      search,
    },
  });
}

function delOrderRecord(id: string) {
  return fetch({
    url: '/admin/orderRecord',
    method: 'DELETE',
    data: {
      id,
    },
  });
}

const AdminApi = {
  login,
  getUserList,
  loginUser,
  deleteUser,
  getStakingList,
  deleteStaking,
  getStakingRecord,
  deleteStakingRecord,
  checkSNOpen,
  getReferer,
  getSendDPRRecord,
  deleteSendDPRRecord,
  getKolList,
  addKol,
  bridgeRecordBsc,
  adscDprRecord,
  setExchangedHash,
  createOrderRecord,
  getOrderlist,
  delOrderRecord,
  stakingCode: {
    list(search?: string) {
      return fetch({
        url: '/admin/stakingCode',
        method: 'GET',
        params: {
          search,
        },
      });
    },
    create(data: { kolId: string; quantity: number }) {
      return fetch({
        url: '/admin/stakingCode',
        method: 'POST',
        data,
      });
    },
  },
  bridgeLimit: {
    list(search?: string) {
      return fetch({
        url: '/admin/bridgeLimit',
        method: 'GET',
        params: {
          search,
        },
      });
    },
    remove(id?: string) {
      return fetch({
        url: '/admin/bridgeLimit',
        method: 'DELETE',
        params: {
          id,
        },
      });
    },
    create(data: { bsc: string; limit: number; note?: string }) {
      return fetch({
        url: '/admin/bridgeLimit',
        method: 'POST',
        data,
      });
    },
  },
  airdrop: {
    async getUser(search: string) {
      return fetch({
        url: '/admin/airdropUser',
        params: {
          search,
        },
      });
    },
  },
};
export default AdminApi;
