import AdminApi from 'apis/admin';
import { useRequest } from 'ahooks';
import { Table, Button, Modal, Tag } from 'antd';
import moment from 'moment';
import Search from 'components/Search/Search';
import { BridgeLimitDto } from 'types/types';

function BridgeLimit() {
  const {
    run: getList,
    data: userList,
    loading: getListLoading,
  } = useRequest(AdminApi.bridgeLimit.list, {
    onSuccess(data) {
      console.log(data);
    },
  });

  const { run: runAddLimit, loading: runAddLimitLoading } = useRequest(
    AdminApi.bridgeLimit.create,
    {
      manual: true,
      onSuccess(data) {
        console.log(data);
        getList();
      },
    },
  );

  const { run: runDelete, loading: deleteStakingLoading } = useRequest(
    AdminApi.bridgeLimit.remove,
    {
      manual: true,
      onSuccess(data) {
        console.log(data);
        getList();
      },
    },
  );

  const deleteItem = (record: BridgeLimitDto) => {
    Modal.confirm({
      title: `确认要删除： ${record.bsc} 记录吗？`,
      onOk() {
        runDelete(record._id);
      },
    });
  };

  return (
    <div>
      <div style={{ marginBottom: 30, display: 'flex', gap: 20 }}>
        <Search onSearch={(val: string) => getList(val)} />
        <Button
          onClick={() => {
            const bsc = prompt('BSC');
            if (!bsc) {
              return;
            }
            const limit = prompt('limit');
            if (!limit || !Number(limit)) {
              return;
            }
            const note = prompt('note') || '';

            runAddLimit({
              bsc,
              limit: Number(limit),
              note,
            });
          }}
        >
          Add Limit
        </Button>
      </div>
      <Table<BridgeLimitDto>
        rowKey='_id'
        dataSource={userList}
        loading={getListLoading}
        columns={[
          {
            title: 'bsc',
            dataIndex: 'bsc',
          },
          {
            title: 'limit',
            dataIndex: 'limit',
          },
          {
            title: 'note',
            dataIndex: 'note',
          },
          {
            title: 'createdAt',
            dataIndex: 'createdAt',
            render(time) {
              if (!time) {
                return '';
              }
              return moment(time).format();
            },
          },
          {
            title: 'action',
            render(_, record) {
              return (
                <div>
                  <Button danger onClick={() => deleteItem(record)}>
                    Delete
                  </Button>
                </div>
              );
            },
          },
        ]}
      />
    </div>
  );
}

export default BridgeLimit;
