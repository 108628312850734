import AdminApi from 'apis/admin';
import { useRequest } from 'ahooks';
import { Table, Button, Modal, Tag } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { useState } from 'react';
import moment from 'moment';
import Search from 'components/Search/Search';
import { StakingCodeItemDto } from 'types/types';

function StakingCode() {
  const {
    run: getList,
    data: userList,
    loading: getListLoading,
  } = useRequest(AdminApi.stakingCode.list, {
    onSuccess(data) {
      console.log(data);
    },
  });
  const { run: runDeleteStaking, loading: deleteStakingLoading } = useRequest(
    AdminApi.deleteStaking,
    {
      manual: true,
      onSuccess(data) {
        console.log(data);
        getList();
      },
    },
  );

  const deleteItem = (record: StakingCodeItemDto) => {
    Modal.confirm({
      title: `确认要删除： ${record.code} 记录吗？`,
      onOk() {
        runDeleteStaking(record._id);
      },
    });
  };

  return (
    <div>
      <div style={{ marginBottom: 30 }}>
        <Search onSearch={(val: string) => getList(val)} />
      </div>
      <Table<StakingCodeItemDto>
        rowKey='_id'
        dataSource={userList}
        loading={getListLoading}
        columns={[
          {
            title: 'kolId',
            dataIndex: 'kolId',
            render(kolId) {
              return (
                <div>
                  <div>{kolId.email}</div>
                  <div style={{ wordBreak: 'break-all' }}>{kolId.username}</div>
                </div>
              );
            },
          },
          {
            title: 'batch',
            dataIndex: 'batch',
            render(val, record) {
              return (
                <div style={{ wordBreak: 'break-all' }}>
                  {val} ({record.batchTotal})
                </div>
              );
            },
          },

          {
            title: 'code',
            dataIndex: 'code',
            render(val) {
              return <div style={{ wordBreak: 'break-all' }}>{val}</div>;
            },
          },

          {
            title: 'binded',
            dataIndex: 'binded',
            render(binded) {
              return binded ? (
                <Tag color='green'>
                  <CheckOutlined />
                </Tag>
              ) : (
                ''
              );
            },
          },
          {
            title: 'bindedTime',
            dataIndex: 'bindedTime',
            render(bindedTime) {
              if (!bindedTime) {
                return;
              }
              return moment(bindedTime).format();
            },
          },
          {
            title: 'sn',
            dataIndex: 'sn',
            render(val) {
              return <div style={{ wordBreak: 'break-all' }}>{val}</div>;
            },
          },
          {
            title: 'deeperChain',
            dataIndex: 'deeperChain',
            render(val) {
              return <div style={{ wordBreak: 'break-all' }}>{val}</div>;
            },
          },
          {
            title: 'action',
            render(_, record) {
              return (
                <div style={{ display: 'flex', gap: 20 }}>
                  <Button
                    onClick={() => {
                      window.open(`/stakingCode?code=${record.code}`);
                    }}
                  >
                    Open
                  </Button>
                  <Button danger onClick={() => deleteItem(record)}>
                    Delete
                  </Button>
                </div>
              );
            },
          },
        ]}
      />
    </div>
  );
}

export default StakingCode;
