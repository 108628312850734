import React, { useState, useEffect, useMemo } from 'react';

import styles from './DprStaking.module.scss';
import {
  Button,
  Input,
  Spin,
  Modal,
  message,
  Tabs,
  ConfigProvider,
  InputNumber,
} from 'antd';
import Header from 'components/Header/Header';
import { useTranslation } from 'react-i18next';
import { ArrowRightOutlined } from '@ant-design/icons';

import {
  createStakingCode,
  ethAuth,
  getNonce,
  getStakingCodeCount,
} from 'apis/dpr';

import BscIcon from 'assets/imgs/BNB-chain.png';
import DeeperIcon from 'assets/imgs/logo-white-sm.png';
import DPRIcon from 'assets/imgs/dpr-coin.png';
import AdscIcon from 'assets/imgs/ADSC.png';
import EthApi, { signMessage } from 'w3/eth/index';
import { getADSCBalance } from 'w3/polkadot';
import { transferAdsc } from 'w3/polkadot';
import { useRequest } from 'ahooks';
import { getCurrentInfo } from 'w3/eth/index';
import { useWeb3 } from 'w3/WWeb3';
import { getAdscConvertedDprQuantity } from 'apis/dpr';

import { StakingRecord } from './StakingRecord';
import { StakingCodeList } from './StakingCodeList';

function DprStaking() {
  const web3 = useWeb3();
  const { t } = useTranslation();
  const [isLogin, setIsLogin] = useState(false);
  const [tabIndex, setTabIndex] = useState('3');
  const [modal, contextHolder] = Modal.useModal();
  const [dprAmount, setDprAmount] = useState(0);
  const [adscAmount, setAdscAmount] = useState(0);

  const {
    data: walletInfo = {
      address: '',
      dprBalance: '0',
      adscBalance: '0',
      dprStaking: '0',
      adscStaking: '0',
      convertedDpr: 0,
      stakingCodeCount: 0,
      remainingDpr: 0,
      remainingConvertedDpr: 0,
    },
    loading: walletInfoLoading,
    run: runGetWalletInfo,
  } = useRequest(
    async () => {
      const address = await web3.getAccount();
      const dprBalance = await web3.getDprBalance(address);
      const adscBalance = await web3.getAdscBalance(address);
      const dprStaking = await web3.getDprStaking(address);
      const adscStaking = await web3.getAdscStaking(address);
      const convertedDpr = await getAdscConvertedDprQuantity();
      const stakingCodeCount = await getStakingCodeCount();
      const remainingDpr = +(
        Number(dprStaking) / 1e18 -
        stakingCodeCount * 50000
      ).toFixed(4);
      const remainingConvertedDpr = +(
        convertedDpr -
        stakingCodeCount * 50000
      ).toFixed(4);
      return {
        address,
        dprBalance,
        adscBalance,
        dprStaking,
        adscStaking,
        convertedDpr,
        stakingCodeCount,
        remainingDpr,
        remainingConvertedDpr,
      };
    },
    {
      manual: true,
      onSuccess(res) {
        console.log(res);
      },
      onError(e) {
        console.log(e.message);
        if (e.message === 'InvalidMetamask') {
          modal.confirm({
            title: 'Install MetaMask',
            content: 'Please install MetaMask',
            onOk: () => {
              window.open(
                'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn',
              );
            },
          });
        }
      },
    },
  );

  const { run: runLogin, loading: runLoginLoading } = useRequest(
    async () => {
      const address = await web3.getAccount();
      const nonce = await getNonce(address);
      if (!nonce) {
        return;
      }
      const signature = await signMessage(nonce, address);
      console.log(signature);
      return ethAuth(address, signature);
    },
    {
      manual: true,
      onSuccess(res) {
        if (res) {
          setIsLogin(true);
          window.localStorage.setItem('ethAuthToken', res);
        }
      },
      onError(e) {
        modal.error({
          content: e.message,
        });
      },
    },
  );

  useEffect(() => {
    runLogin();
  }, []);
  useEffect(() => {
    if (isLogin) {
      runGetWalletInfo();
    }
  }, [isLogin]);

  const { run: runStake, loading: runStakeLoading } = useRequest(
    async (coin, address, amount) => {
      if (coin === 'dpr') {
        return web3.dprStake(address, amount);
      } else if (coin === 'adsc') {
        return web3.adscStake(address, amount);
      }
    },
    {
      manual: true,
      onSuccess(res) {
        console.log(res);
      },
    },
  );

  const { run: runCreateStakingCode, loading: runCreateStakingCodeLoading } =
    useRequest(createStakingCode, {
      manual: true,
      onSuccess(res) {
        console.log(res);
      },
    });
  return (
    <main className={styles['container']}>
      <Header white></Header>
      <div>
        <div className={styles['bridge-main']}>
          <h2 className={styles['bridge-title']}>
            <img style={{ width: 40 }} src={AdscIcon} alt='' />
            DPR {t('Staking')}
          </h2>

          <div className={styles['account-box']}>
            <div className={styles['box-title']}>BSC {t('Wallet')}</div>
            <div className={styles['input-box']}>
              <div className={styles['show-box']}>{walletInfo.address}</div>
            </div>
          </div>
          <div className={styles['staking-info-box']}>
            <div></div>
          </div>
          <div style={{ marginTop: 20 }}>
            <ConfigProvider
              theme={{
                components: {
                  Tabs: {
                    cardBg: 'rgba(255,255,255,0.2)',
                    itemColor: 'rgba(255, 255, 255, 0.88)',
                  },
                },
              }}
            >
              <Tabs
                centered
                type='card'
                defaultActiveKey='3'
                onChange={(key) => {
                  console.log(key);
                  setTabIndex(key);
                }}
                items={[
                  {
                    key: '3',
                    label: t('Device Staking'),
                    children: isLogin && (
                      <div>
                        <div className={styles['staking-summary-box']}>
                          <div className={styles['staking-summary-half']}>
                            <div className={styles['staking-summary-row']}>
                              <div className={styles['title-box']}>
                                {t('Total DPR staked')}:
                              </div>
                              <div className={styles['value-box']}>
                                {+(walletInfo.dprStaking / 1e18).toFixed(4)} DPR
                              </div>
                            </div>
                            <div className={styles['staking-summary-row']}>
                              <div className={styles['title-box']}>
                                {t('Total ADSC staked')}:
                              </div>
                              <div className={styles['value-box']}>
                                {+(walletInfo.adscStaking / 1e18).toFixed(4)}{' '}
                                ADSC
                              </div>
                            </div>
                            <div className={styles['staking-summary-row']}>
                              <div className={styles['title-box']}>
                                {t('Total converted DPR')}:
                              </div>
                              <div className={styles['value-box']}>
                                {+walletInfo.convertedDpr.toFixed(4)} DPR
                              </div>
                            </div>
                          </div>

                          <div className={styles['staking-summary-half']}>
                            <div className={styles['staking-summary-row']}>
                              <div className={styles['title-box']}>
                                {t('The count of staking codes')}:
                              </div>
                              <div className={styles['value-box']}>
                                {walletInfo.stakingCodeCount}
                              </div>
                            </div>
                            <div className={styles['staking-summary-row']}>
                              <div className={styles['title-box']}>
                                {t('DPR used')}:
                              </div>
                              <div className={styles['value-box']}>
                                {walletInfo.stakingCodeCount * 50000} DPR
                              </div>
                            </div>
                            <div className={styles['staking-summary-row']}>
                              <div className={styles['title-box']}>
                                {t('DPR available')}:
                              </div>
                              <div className={styles['value-box']}>
                                {walletInfo.remainingDpr} DPR
                              </div>
                            </div>
                            <div className={styles['staking-summary-row']}>
                              <div className={styles['title-box']}>
                                {t('Converted DPR used')}:
                              </div>
                              <div className={styles['value-box']}>
                                {walletInfo.stakingCodeCount * 50000} DPR
                              </div>
                            </div>

                            <div className={styles['staking-summary-row']}>
                              <div className={styles['title-box']}>
                                {t('Converted DPR available')}:
                              </div>
                              <div className={styles['value-box']}>
                                {walletInfo.remainingConvertedDpr} DPR
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={styles['staking-btn-box']}>
                          <Button
                            size='large'
                            type='primary'
                            loading={runCreateStakingCodeLoading}
                            disabled={
                              walletInfo.remainingDpr < 50000 ||
                              walletInfo.remainingConvertedDpr < 50000
                            }
                            onClick={() => {
                              runCreateStakingCode();
                            }}
                          >
                            {t(
                              'Pay 50,000 DPR and 50,000 converted DPR to get a staking code',
                            )}
                          </Button>
                        </div>
                      </div>
                    ),
                  },
                  {
                    key: '1',
                    label: `DPR ${t('Staking')}`,
                    children: (
                      <div className={styles['staking-container']}>
                        <div className={styles['staking-box']}>
                          <div className={styles['balance-value-box']}>
                            <div className={styles['balance-value']}>
                              <div style={{ color: '#aaa' }}>
                                DPR {t('Balance in wallet')}:{' '}
                              </div>
                              <Spin spinning={walletInfoLoading}>
                                <div>
                                  {+(walletInfo.dprBalance / 1e18).toFixed(5)}{' '}
                                  DPR
                                </div>
                              </Spin>
                            </div>
                          </div>
                          <div className={styles['input-box']}>
                            <ConfigProvider
                              theme={{
                                components: {
                                  InputNumber: {
                                    controlWidth: 200,
                                    addonBg: 'rgba(255,255,255,0.8)',
                                  },
                                },
                              }}
                            >
                              <InputNumber
                                addonAfter='DPR'
                                min={0}
                                max={+(walletInfo.dprBalance / 1e18).toFixed(5)}
                                placeholder={t('Input transfer amount')}
                                onChange={(val) => {
                                  setDprAmount(val || 0);
                                }}
                                value={dprAmount}
                                precision={0}
                              ></InputNumber>
                            </ConfigProvider>
                          </div>
                          <Button
                            onClick={() =>
                              runStake('dpr', walletInfo.address, dprAmount)
                            }
                            loading={runStakeLoading}
                          >
                            {t('Stake')}
                          </Button>
                        </div>

                        <div className={styles['record-box']}>
                          <div className={styles['staking-total-box']}>
                            <div>
                              {t('Total DPR staked')}:{' '}
                              {+(walletInfo.dprStaking / 1e18).toFixed(4)} DPR
                            </div>
                          </div>
                          {walletInfo.address && isLogin && (
                            <StakingRecord coin='DPR'></StakingRecord>
                          )}
                        </div>
                      </div>
                    ),
                  },
                  {
                    key: '2',
                    label: `ADSC ${t('Staking')}`,
                    children: (
                      <div className={styles['staking-container']}>
                        <div className={styles['staking-box']}>
                          <div className={styles['balance-value-box']}>
                            <div className={styles['balance-value']}>
                              <div style={{ color: '#aaa' }}>
                                ADSC {t('Balance in wallet')}:{' '}
                              </div>
                              <Spin spinning={walletInfoLoading}>
                                <div>
                                  {+(walletInfo.adscBalance / 1e18).toFixed(5)}{' '}
                                  ADSC
                                </div>
                              </Spin>
                            </div>
                          </div>
                          <div className={styles['input-box']}>
                            <ConfigProvider
                              theme={{
                                components: {
                                  InputNumber: {
                                    controlWidth: 200,
                                    addonBg: 'rgba(255,255,255,0.8)',
                                  },
                                },
                              }}
                            >
                              <InputNumber
                                addonAfter='ADSC'
                                min={0}
                                max={
                                  +(walletInfo.adscBalance / 1e18).toFixed(5)
                                }
                                placeholder={t('Input transfer amount')}
                                onChange={(val) => {
                                  setAdscAmount(val || 0);
                                }}
                                value={adscAmount}
                                precision={0}
                              ></InputNumber>
                            </ConfigProvider>
                          </div>
                          <Button
                            onClick={() =>
                              runStake('adsc', walletInfo.address, adscAmount)
                            }
                            loading={runStakeLoading}
                          >
                            {t('Stake')}
                          </Button>
                        </div>

                        <div className={styles['record-box']}>
                          <div className={styles['staking-total-box']}>
                            <div>
                              {t('Total ADSC staked')}:{' '}
                              {+(walletInfo.dprStaking / 1e18).toFixed(4)} ADSC
                            </div>
                            <div>
                              {t('Total converted DPR')}:{' '}
                              {walletInfo.convertedDpr} DPR
                            </div>
                          </div>
                          {walletInfo.address && isLogin && (
                            <StakingRecord coin='ADSC'></StakingRecord>
                          )}
                        </div>
                      </div>
                    ),
                  },
                ]}
              ></Tabs>
            </ConfigProvider>
          </div>
        </div>
      </div>
      {tabIndex === '3' && isLogin && !runCreateStakingCodeLoading && (
        <div className={styles['staking-code-box']}>
          <StakingCodeList></StakingCodeList>
        </div>
      )}
      {contextHolder}
    </main>
  );
}

export default DprStaking;
