import React, { useEffect, useState } from 'react';
import styles from './BatchQuery.module.scss';
import worldImg from 'assets/imgs/worldmap-bg.png';
import { useTranslation } from 'react-i18next';
import { Button, Upload, Table, Tag } from 'antd';
import { UploadOutlined, ReloadOutlined } from '@ant-design/icons';
import Header from 'components/Header/Header';

import { useRequest } from 'ahooks';
import { getADSCBalance, getImOnline } from 'w3/polkadot';
import AdscApi from 'apis/adsc';

function downloadTxtFile(url, fileName) {
  const element = document.createElement('a');
  element.href = url;
  element.download = fileName;
  document.body.appendChild(element); // 将 <a> 元素添加到文档中
  element.click(); // 模拟点击下载链接
  document.body.removeChild(element); // 下载完成后从文档中移除 <a> 元素
}

export default function BatchQuery() {
  const { t } = useTranslation();
  const [addressList, setAddressList] = useState([]);
  const [addressMap, setAddressMap] = useState({});
  const [imOnlineMap, setImOnlineMap] = useState({});
  const [activeAddress, setActiveAddress] = useState('');
  const [totalBalance, setTotalBalance] = useState(0);

  const { runAsync: runGetBalance, loading: getBalanceLoading } = useRequest(
    async (deeperChain) => {
      const balanceRes = await getADSCBalance(deeperChain);
      const imOnlineRes = await getImOnline(deeperChain);
      console.log(balanceRes, imOnlineRes);
      return {
        balance: balanceRes,
        imOnline: imOnlineRes,
      };
    },
    {
      manual: true,
    },
  );

  const updateAddressMap = (address, res) => {
    const { balance = { balance: 0 }, imOnline = false } = res || {};
    addressMap[address] = balance ? (balance.balance / 1e18).toFixed(2) / 1 : 0;
    let totalBalance = Object.values(addressMap).reduce(
      (pre, now) => {
        return (pre / 1 + now / 1).toFixed(2) / 1;
      },
      [0],
    );
    imOnlineMap[address] = imOnline;
    setTotalBalance(totalBalance);
    setAddressMap(Object.assign({}, addressMap));
    setImOnlineMap(Object.assign({}, imOnlineMap));
  };

  const getBalance = (address) => {
    setActiveAddress(address);
    return runGetBalance(address)
      .then((res) => {
        const { balance, imOnline } = res;
        updateAddressMap(address, res);
      })
      .finally(() => {
        setActiveAddress('');
      });
  };

  useEffect(() => {
    (async () => {
      for (let addressItem of addressList) {
        let address = addressItem.address;
        console.log(`start get ${address}`);
        await getBalance(address);
      }
    })();
  }, [addressList]);

  const columns = [
    {
      title: t('Address'),
      dataIndex: 'address',
      key: 'address',
      className: styles['address-box'],
    },
    {
      title: t('Balance'),
      dataIndex: 'address',
      key: 'address',
      render(address) {
        return addressMap[address];
      },
    },
    {
      title: t('Online'),
      dataIndex: 'address',
      key: 'address',
      render(address) {
        if (imOnlineMap[address] === undefined) {
          return;
        }
        return imOnlineMap[address] ? (
          <Tag color='success'>Online</Tag>
        ) : (
          <Tag color='error'>Offline</Tag>
        );
      },
    },
    {
      title: '',
      dataIndex: 'address',
      key: 'address',
      width: 200,
      render(address) {
        return (
          <div className={styles['tool-btn-box']}>
            <Button
              loading={activeAddress == address}
              onClick={() => {
                getBalance(address);
              }}
              icon={<ReloadOutlined />}
            ></Button>

            <Button
              onClick={() => {
                window.open(`/adsc?deeperChain=${address}`);
              }}
            >
              {t('Detail')}
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div
      className={styles['container']}
      style={{
        backgroundImage: `url(${worldImg})`,
      }}
    >
      <Header></Header>
      <div className={styles['btn-box']}>
        <Button
          onClick={() => {
            downloadTxtFile('/address.txt', 'address.txt');
          }}
        >
          {t('Download template file')}
        </Button>
        <Upload
          showUploadList={false}
          beforeUpload={(file) => {
            var reader = new FileReader();
            reader.readAsText(file, 'UTF-8');
            console.log(file);
            reader.onload = function (evt) {
              let fileString = evt.target.result;
              console.log(fileString);
              const addressList = fileString
                .replace(/\r/g, '')
                .split('\n')
                .filter((it) => !!it);
              console.log(addressList);
              setAddressMap({});
              setAddressList(
                addressList.map((it) => {
                  return {
                    address: it,
                    key: it,
                  };
                }),
              );
            };

            return false;
          }}
        >
          <Button type='primary' icon={<UploadOutlined />}>
            {t('Click to Upload')}
          </Button>
        </Upload>
      </div>

      <div className={styles['table-box']}>
        <div className={styles['summary-box']}>
          <div style={{ marginBottom: 30 }}>
            总个数：{addressList.length} 在线：
            {Object.values(imOnlineMap).filter((it) => !!it).length} 离线：
            {addressList.length -
              Object.values(imOnlineMap).filter((it) => !!it).length}
          </div>
          <div>
            {t('Total Balance')}:{' '}
            <span
              style={{
                display: 'inline-block',
                padding: '5px 15px',
                background: '#000',
                color: '#fff',
                fontSize: 18,
              }}
            >
              {totalBalance}
            </span>{' '}
            ADSC
          </div>
        </div>
        <Table pagination={false} dataSource={addressList} columns={columns} />
      </div>
    </div>
  );
}
