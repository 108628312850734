import Web3 from 'web3';

import { TransactionReceipt } from 'web3-core';
import { DprToken } from './eth/DPR/DprToken';
import { AdscToken } from './eth/ADSC/AdscToken';
import { useMemo } from 'react';
import { DprStakingToken } from './eth/DPRStaking/DprStaknigToken';
import { AdscStakingToken } from './eth/AdscStaking/AdscStaknigToken';

import { Contract } from 'web3-eth-contract';

export class WWeb3 {
  public web3: Web3;
  public chainId: number = 0;
  isMetamask: boolean;

  constructor(chainId: number, isMetamask?: boolean) {
    this.chainId = chainId;
    this.isMetamask = isMetamask || false;
    console.log('init');
    if (isMetamask) {
      if (typeof window.ethereum === 'undefined') {
        this.web3 = new Web3();
        return;
      }
      this.web3 = new Web3(window.ethereum);
      this.initMetamask();
      return;
    }
    let rpcUrl;

    const rpcUrls: { [chainId: number]: string } = {
      1: 'https://mainnet.infura.io/v3/41f7a3c42f574496aaafdf1760dcf4a1', // Replace with your Infura Project ID
      56: 'https://bsc-dataseed.binance.org/', // Binance Smart Chain RPC
    };

    if (rpcUrls[chainId]) {
      rpcUrl = rpcUrls[chainId];
    } else {
      throw new Error('InvalidChainId');
    }
    this.web3 = new Web3(new Web3.providers.HttpProvider(rpcUrl));
    this.chainId = chainId;
  }

  async getWeb3() {
    if (!this.isMetamask) {
      return this.web3;
    }
    const chainId = await this.getChainId();
    if (chainId !== this.chainId) {
      await this.switchNetwork();
    }
    return this.web3;
  }

  initMetamask() {
    window.ethereum.on('accountsChanged', function () {
      window.location.reload();
    });

    window.ethereum.on('chainChanged', () => {
      window.location.reload();
    });
  }

  async switchNetwork() {
    if (this.chainId === 56) {
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: '0x38', // BSC 网络的 Chain ID
            chainName: 'Smart Chain',
            nativeCurrency: {
              name: 'BNB',
              symbol: 'BNB',
              decimals: 18,
            },
            rpcUrls: ['https://bsc-dataseed.binance.org/'], // BSC 节点的 URL
            blockExplorerUrls: ['https://bscscan.com/'], // 区块浏览器的 URL
          },
        ],
      });
    }
  }

  async getAccount() {
    if (!window.ethereum && this.isMetamask) {
      throw new Error('InvalidMetamask');
    }
    console.log('getAccount');
    const accounts = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });
    return accounts[0];
  }

  async getChainId() {
    return await this.web3.eth.getChainId();
  }

  async getDprBalance(address: string) {
    await this.getWeb3();
    const token = new DprToken(this.web3, this.chainId);
    return token.getBalance(address);
  }

  async getAdscBalance(address: string) {
    await this.getWeb3();
    const token = new AdscToken(this.web3, this.chainId);
    return token.getBalance(address);
  }

  async getDprStaking(address: string) {
    await this.getWeb3();
    const token = new DprStakingToken(this.web3, this.chainId);
    return token.getStaking(address);
  }
  async getAdscStaking(address: string) {
    await this.getWeb3();
    const token = new AdscStakingToken(this.web3, this.chainId);
    return token.getStaking(address);
  }

  async dprStake(address: string, amount: number) {
    await this.getWeb3();
    const token = new DprStakingToken(this.web3, this.chainId);
    const staking = await this.getDprStaking(address);
    console.log('staking', staking);
    if (Number(staking) !== 0) {
      return token.addAndExtendStaking(address, amount);
    }
    return token.stake(address, amount);
  }

  async adscStake(address: string, amount: number) {
    await this.getWeb3();
    const token = new AdscStakingToken(this.web3, this.chainId);
    const staking = await this.getAdscStaking(address);
    if (Number(staking) !== 0) {
      return token.addAndExtendStaking(address, amount);
    }
    return token.stake(address, amount);
  }
}

export function useWeb3(chainId: number = 56) {
  const web3 = useMemo(() => {
    return new WWeb3(chainId, true);
  }, [chainId]);
  return web3;
}

export async function sendTx(
  web3: Web3,
  contractMethod: Contract['methods'][keyof Contract['methods']], // This represents a contract method
  from: string,
): Promise<TransactionReceipt> {
  const gasPrice = await web3.eth.getGasPrice();
  // First, we estimate the gas for the contract method
  const gasEstimate = await contractMethod.estimateGas({ from });

  // Then, we send the transaction
  return new Promise((resolve, reject) => {
    contractMethod
      .send({
        from,
        gas: Math.floor(gasEstimate * 1.2),
        gasPrice,
      })
      .on('transactionHash', (hash: string) => {
        console.log('Transaction Hash:');
        console.log(hash);
      })
      .on(
        'confirmation',
        (confirmationNumber: number, receipt: TransactionReceipt) => {
          console.log('Confirmation:');
          console.log(confirmationNumber, receipt);
        },
      )
      .on('receipt', (receipt: TransactionReceipt) => {
        console.log('Receipt:');
        resolve(receipt); // Resolve the promise with the transaction receipt
      })
      .on('error', (error: Error) => {
        console.log('Error:');
        reject(error); // Reject the promise if there is an error
      });
  });
}
