import fetch from './dFetch';

function getBalance(address) {
  return fetch({
    url: '/adsc/balance',
    method: 'GET',
    params: {
      address,
    },
  });
}

function getBridgeRecord({ deeperChain, eth, chainId }) {
  return fetch({
    url: '/adsc/bridgeRecord',
    method: 'GET',
    params: {
      deeperChain,
      eth,
      chainId,
    },
  });
}

function getNft(deeperChain) {
  return fetch({
    url: '/adsc/nft',
    method: 'GET',
    params: {
      deeperChain,
    },
  });
}

function getStakingInfo(deeperChain) {
  return fetch({
    url: '/adsc/stakingInfo',
    method: 'GET',
    params: {
      deeperChain,
    },
  });
}

function getRewardRecord(deeperChain) {
  return fetch({
    url: '/adsc/rewardRecord',
    method: 'GET',
    params: {
      deeperChain,
    },
  });
}

function submitAdscToDpr({ deeperChain, bsc, amount, hash }) {
  return fetch({
    url: '/adsc/submitAdscToDpr',
    method: 'POST',
    data: {
      deeperChain,
      bsc,
      amount,
      hash,
    },
  });
}

function adscToDprRecord(deeperChain) {
  return fetch({
    url: '/adsc/adscToDprRecord',
    method: 'GET',
    params: {
      deeperChain,
    },
  });
}

function cancelAdscToDpr(id) {
  return fetch({
    url: '/adsc/cancelAdscToDpr',
    method: 'POST',
    params: {
      id,
    },
  });
}

function checkHaveRecord(deeperChain) {
  return fetch({
    url: '/adsc/checkHaveRecord',
    method: 'GET',
    params: {
      deeperChain,
    },
  });
}

function getBridgeRemainingAmount(address) {
  return fetch({
    url: '/adsc/bridgeRemainingAmount',
    method: 'GET',
    params: {
      address,
    },
  });
}

export default {
  getBalance,
  getBridgeRecord,
  getNft,
  getStakingInfo,
  getRewardRecord,
  submitAdscToDpr,
  adscToDprRecord,
  cancelAdscToDpr,
  checkHaveRecord,
  getBridgeRemainingAmount,
};
