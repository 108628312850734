import fetch from './fetch';

function login(data) {
  return fetch({
    url: '/k/login',
    method: 'POST',
    data,
  }).then((res) => {
    if (res) {
      window.localStorage.setItem('token', res.token);
      window.localStorage.setItem('tokenData', JSON.stringify(res.tokenData));
    }
    return res;
  });
}

function checkUsername(username) {
  return fetch({
    url: '/k/checkUsername',
    method: 'POST',
    data: {
      username,
    },
  });
}

function getStakingInfo() {
  return fetch({
    url: '/k/stakingInfo',
    method: 'GET',
  });
}

function stake(deeperChain) {
  return fetch({
    url: '/k/stake',
    method: 'POST',
    data: {
      deeperChain,
    },
  });
}

function stakingList(search) {
  return fetch({
    url: '/k/stakingList',
    method: 'GET',
    params: {
      search,
    },
  });
}

export default {
  checkUsername,
  login,
  getStakingInfo,
  stake,
  stakingList,
};
